import './ImageCTABanner.scss';

import { extractAssetURL } from '@api/utils';
import { Arrow } from '@components/ui/Icons';
import { Link } from 'react-router-dom';

const ImageCTABanner = ({ data }) => {
    const imageWrapperStyle = {
        backgroundImage: `url(${extractAssetURL(data?.image?.data?.attributes)})`
    };

    return (
        <div className="image-cta-banner">
            <div className="container">
                <div className="image-wrapper" style={imageWrapperStyle}>
                    <div className="text-wrapper">
                        <span className="label">{data?.label}</span>

                        <h2 className="heading heading--large">
                            {data?.title}
                        </h2>
                        <Link to={data?.link_url} className="link arrow">
                            {data?.link_label}
                            <Arrow />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ImageCTABanner;
