import './HeaderAlt.scss';

import { extractAssetURL } from 'api/utils';

const HeaderAlt = ({ data }) => {
    return (
        <section className="Header__showdown-alt">
            <img
                src={extractAssetURL(data?.background?.data?.attributes)}
                alt="ESPN De Ontknoping promo afbeelding"
                className="banner-img"
            />
        </section>
    );
};

export default HeaderAlt;
