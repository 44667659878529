import './QRCodeBlock.scss';

import { useEffect, useState } from 'react';

import classNames from 'classnames';
import QRCode from 'react-qr-code';

const QR_URL = `${window.location.origin}/goal/app-store`;

const QRCodeBlock = () => {
    const [isShadowVisible, setIsShadowVisible] = useState(true);

    useEffect(() => {
        const checkScroll = () => {
            let innerHeight = 900;

            if (window.innerWidth < 1024) {
                innerHeight = 1800;
            }

            const threshold =
                document.body.scrollHeight - window.innerHeight - innerHeight;

            setIsShadowVisible(window.scrollY < threshold);
        };

        window.addEventListener('scroll', checkScroll);

        return () => window.removeEventListener('scroll', checkScroll);
    }, []);

    return (
        <div
            className={classNames('qr-code-block', {
                'no-shadow': !isShadowVisible,
                shadow: isShadowVisible
            })}
        >
            <QRCode
                size={256}
                style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                value={QR_URL}
            />
            <div className="content">
                <h3 className="heading heading--small">
                    Bekijk de goal in de app
                </h3>
                <p className="text">
                    Maak een{' '}
                    <span className="text--red text--bold">gratis</span> account
                    en krijg goals van je favoriete club(s) binnen no-time op je
                    telefoon!
                </p>
            </div>
        </div>
    );
};

export default QRCodeBlock;
