import './Cta.scss';

import { Button } from '@components/ui/Button';
import Image from '@components/ui/Image';

const Cta = ({ data }) => {
    return (
        <section className="Cta">
            <div className="container">
                <div className="block-wrapper">
                    <div className="block text--center block--column">
                        <Image
                            image={data?.image?.data?.attributes}
                            className="img-responsive"
                        />

                        <h1 className="heading heading--large heading--white">
                            {data?.title}
                        </h1>
                        <span className="text text--white">
                            {data?.description}
                        </span>

                        {data?.buttons?.map(button => (
                            <Button
                                key={button.id}
                                label={button.label}
                                link={button.url}
                                type={button.type}
                                target={button.is_external ? '_blank' : '_self'}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Cta;
