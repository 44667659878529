import './AppStoreBanner.scss';

import { useCallback, useMemo } from 'react';

import AppIcon from '../../../../assets/icons/Other/app-icon.png';
import { Button } from '../../../../components/ui/Button';
import useAppStore from '../../../../hooks/useAppStore';

const AppStoreBanner = () => {
    const appStore = useAppStore();

    const appStoreIcon = useMemo(
        () => <img src={appStore.icon} alt={appStore.iconAlt} />,
        [appStore]
    );

    const handleAppStoreButtonClick = useCallback(() => {
        window.location.replace(appStore.appUrl);
    }, [appStore]);

    return (
        <div className="app-store-banner">
            <img
                src={AppIcon}
                alt="App Store Icon"
                className="app-store-banner__icon"
            />
            <div className="content">
                <h3 className="heading heading--small">
                    Bekijk de goal in de app
                </h3>

                <p className="text">
                    Maak een{' '}
                    <span className="text--red text--bold">gratis</span> account
                    en krijg goals van je favoriete club(s) binnen no-time op je
                    telefoon!
                </p>

                <Button
                    label="Download in de App Store"
                    type="primary"
                    icon={appStoreIcon}
                    onClick={handleAppStoreButtonClick}
                />
            </div>
        </div>
    );
};

export default AppStoreBanner;
