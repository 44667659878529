import './PartnerBlock.scss';

import Image from '@components/ui/Image';

const PartnerBlock = ({ data }) => {
    return (
        <div className="partner-block">
            {data?.logo?.data && <Image image={data?.logo?.data?.attributes} />}
            <div className="content-wrapper">
                <h3 className="heading--medium">{data?.name}</h3>
                <p className="text">{data?.description}</p>
            </div>
        </div>
    );
};

export default PartnerBlock;
