import { useEffect } from 'react';
import useAppStore from '../../hooks/useAppStore';

const AppStore = () => {
    const appStore = useAppStore();

    useEffect(() => {
        window.location.replace(appStore.appUrl);
    }, [appStore]);

    return null;
};

export default AppStore;
