import { Arrow } from '@components/ui/Icons';
import Image from '@components/ui/Image';
import { Link } from 'react-router-dom';

const CampaignSmall = ({ campaign }) => {
    return (
        <div className="block" key={campaign.title}>
            <h1 className="heading heading--large heading--white">
                {campaign.title}
            </h1>
            <Link className="text arrow" to={campaign.link}>
                {campaign.linkLabel}
                <Arrow />
            </Link>

            <Image
                image={campaign.image?.data?.attributes}
                className="block__image img-responsive"
            />
        </div>
    );
};

export default CampaignSmall;
