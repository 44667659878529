import API from '@api/api';
import qs from 'qs';

const populateConfig = {
    header: {
        populate: ['background', 'buttons']
    },
    meta: {
        populate: ['image']
    },
    video: true,
    video_poster: true,
    match_overview_background: true
};

const ontknopingPageQueries = {
    getPageData: {
        key: 'ontknopingPageData',
        fetcher: async () => {
            const query = qs.stringify(
                {
                    populate: populateConfig
                },
                {
                    encodeValuesOnly: true // prettify URL
                }
            );

            return API.fetch(`/ontknoping-page?${query}`);
        }
    }
};

export { ontknopingPageQueries };
