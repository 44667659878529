import {
    abonnerenPageQueries,
    calendarPageQueries,
    competitionsQueries,
    downloadPageQueries,
    dynamicPageQueries,
    espnOneQueries,
    fairPlayPageQueries,
    frontPageQueries,
    goalPageQueries,
    matchesQueries,
    navigationQueries,
    ontknopingPageQueries,
    partnersPageQueries,
    programmeringPageQueries
} from '@api/queries';

import { useQuery } from '@tanstack/react-query';
import { clubsQueries } from 'api/queries/clubs.queries';

const useStrapiQueries = () => {
    const useFetchFooterData = () =>
        useQuery({
            queryKey: [navigationQueries.getFooterData.key],
            queryFn: navigationQueries.getFooterData.fetcher
        });

    const useFetchFrontPage = () =>
        useQuery({
            queryKey: [frontPageQueries.getFrontPageData.key],
            queryFn: frontPageQueries.getFrontPageData.fetcher
        });

    const useFetchAbonnerenPage = () =>
        useQuery({
            queryKey: [abonnerenPageQueries.getPageData.key],
            queryFn: abonnerenPageQueries.getPageData.fetcher
        });

    const useFetchESPNOnePage = () =>
        useQuery({
            queryKey: [espnOneQueries.getPageData.key],
            queryFn: espnOneQueries.getPageData.fetcher
        });

    const useFetchDownloadPage = () =>
        useQuery({
            queryKey: [downloadPageQueries.getPageData.key],
            queryFn: downloadPageQueries.getPageData.fetcher
        });

    const useFetchPartnersPage = () =>
        useQuery({
            queryKey: [partnersPageQueries.getPageData.key],
            queryFn: partnersPageQueries.getPageData.fetcher
        });

    const useFetchDynamicPage = slug =>
        useQuery({
            queryKey: [`${dynamicPageQueries.getPageData.key}-${slug}`],
            queryFn: () => dynamicPageQueries.getPageData.fetcher(slug)
        });

    const useFetchProgrammeringPage = () =>
        useQuery({
            queryKey: [programmeringPageQueries.getPageData.key],
            queryFn: programmeringPageQueries.getPageData.fetcher
        });
    const useFetchCalendarPage = () =>
        useQuery({
            queryKey: [calendarPageQueries.getPageData.key],
            queryFn: calendarPageQueries.getPageData.fetcher
        });

    const useFetchCompetitions = () =>
        useQuery({
            queryKey: [competitionsQueries.getPageData.key],
            queryFn: competitionsQueries.getPageData.fetcher
        });

    const useFetchFairPlayPage = () =>
        useQuery({
            queryKey: [fairPlayPageQueries.getPageData.key],
            queryFn: fairPlayPageQueries.getPageData.fetcher
        });

    const useFetchOntknopingPage = () =>
        useQuery({
            queryKey: [ontknopingPageQueries.getPageData.key],
            queryFn: ontknopingPageQueries.getPageData.fetcher
        });

    const useFetchGoalPage = () =>
        useQuery({
            queryKey: [goalPageQueries.getPageData.key],
            queryFn: goalPageQueries.getPageData.fetcher
        });

    const useFetchMatchesData = ({ filter, sort }) =>
        useQuery({
            queryKey: [matchesQueries.getMatchesData.key],
            queryFn: () =>
                matchesQueries.getMatchesData.fetcher({ filter, sort })
        });
    const useFetchClubsData = () =>
        useQuery({
            queryKey: [clubsQueries.getClubsData.key],
            queryFn: clubsQueries.getClubsData.fetcher
        });

    return {
        useFetchFooterData,
        useFetchFrontPage,
        useFetchDynamicPage,
        useFetchAbonnerenPage,
        useFetchESPNOnePage,
        useFetchDownloadPage,
        useFetchPartnersPage,
        useFetchProgrammeringPage,
        useFetchCalendarPage,
        useFetchCompetitions,
        useFetchFairPlayPage,
        useFetchOntknopingPage,
        useFetchMatchesData,
        useFetchGoalPage,
        useFetchClubsData
    };
};

export default useStrapiQueries;
