// hook that uses the redirect function from react-router-dom to redirect to a 404 page if the data is not found.

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useRedirect404 = (loading, error) => {
    const navigate = useNavigate();

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (error) {
            navigate('/404');
        }
    }, [error, loading, navigate]);
};

export default useRedirect404;
