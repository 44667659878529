import { STRAPI_URL } from 'env';

const API_URL = `${STRAPI_URL}/api`;

const API = {
    fetch: async url => {
        if (!url) {
            throw new Error('URL is required');
        }
        const response = await fetch(`${API_URL}${url}`);
        const data = await response.json();
        return data;
    }
};

export default API;
