// hook that returns the device type (android or iOS) based on the user agent

import { useEffect, useState } from 'react';

const useDeviceType = () => {
    const [deviceType, setDeviceType] = useState(null);

    useEffect(() => {
        const userAgent =
            navigator.userAgent || navigator.vendor || window.opera;

        if (/android/i.test(userAgent)) {
            setDeviceType('android');
        } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            setDeviceType('ios');
        } else if (/iPad|iPhone|iPod/.test(userAgent) && window.MSStream) {
            setDeviceType('windowsPhone');
        } else {
            setDeviceType('unknown');
        }
    }, []);

    return deviceType;
};

export default useDeviceType;
