import './TabbedContent.scss';

import calendar from '@assets/icons/Other/calendar.png';
import union from '@assets/icons/Other/union.png';
import Image from '@components/ui/Image';
import { trackLink } from '@scripts/analytics';
import { useState } from 'react';
import check from '../../.././../assets/icons/Other/check.png';

const TabbedContent = ({ id, tabs }) => {
    const [active, setActive] = useState(0);

    const clickTeam = team => {
        const link = `campagne:kalender:${team.name}`;

        trackLink(link, link);

        team.onclick();
    };

    return (
        <section id={id} className="TabbedContent mt7">
            <div className="container px2">
                <h2 className="heading heading--medium text--center mb4">
                    Hoe werkt het
                </h2>
                <div className="d-flex text description">
                    <div className="line" />
                    <div className="step d-flex">
                        <div className="circle d-flex">
                            <img src={union} alt="team icon" />
                        </div>
                        <div className="content">
                            <h3 className="my1">Kies een club</h3>
                            <p className="text">
                                Kies je favoriete club waar je geen wedstrijden
                                van wilt missen.
                            </p>
                        </div>
                    </div>
                    <div className="step d-flex">
                        <div className="circle d-flex">
                            <img src={calendar} alt="team icon" />
                        </div>
                        <div className="content">
                            <h3 className="my1">Selecteer je agenda</h3>
                            <p className="text">
                                Kies jouw agenda app die jij fijn vindt.
                            </p>
                        </div>
                    </div>
                    <div className="step d-flex">
                        <div className="circle d-flex">
                            <img src={check} alt="team icon" />
                        </div>
                        <div className="content">
                            <h3 className="my1">Bevestig</h3>
                            <p className="text">
                                Bevestig je selectie en krijg alle wedtrijden in
                                je agenda
                            </p>
                        </div>
                    </div>
                </div>
                <div className="container container--small">
                    <div className="Tabs mt6 mb4">
                        {tabs?.map((tab, i) => {
                            return (
                                // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
                                <div
                                    key={tab.name}
                                    className={`Tab${active === i ? ' active' : ''}`}
                                    onClick={() => setActive(i)}
                                >
                                    {tab.img && (
                                        <Image
                                            image={tab.img}
                                            alt={tab.name}
                                            size="thumbnail"
                                        />
                                    )}

                                    <span className="text">{tab.name}</span>
                                </div>
                            );
                        })}
                    </div>
                    <div className="grid-container pt3 pb3">
                        <div className="tiles">
                            {tabs?.[active]?.content?.map(team => (
                                // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
                                <div
                                    key={team.name}
                                    className="tile"
                                    onClick={() => {
                                        clickTeam(team);
                                    }}
                                >
                                    {team?.img && (
                                        <Image
                                            image={team.img}
                                            alt={`${team.name} Logo`}
                                            size="thumbnail"
                                            className="img-responsive"
                                        />
                                    )}

                                    <span className="text">{team.name}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TabbedContent;
