import './Header.scss';

import { extractAssetURL, parseMarkdownContent } from '@api/utils';

import logo from '@assets/images/logo-espn.svg';
import { Button } from '@components/ui/Button';
import classNames from 'classnames';

const Header = ({ data }) => {
    const headerStyle = {
        backgroundImage: `url(${extractAssetURL(data?.background?.data?.attributes)})`
    };

    const headerInnerClasses = classNames('container--inner', {
        'text--center': data?.position === 'center',
        'text--right': data?.position === 'right',
        'text--left': data?.position === 'left'
    });

    const headerContentClasses = classNames('content', {
        'content--center': data?.position === 'center',
        'content--right': data?.position === 'right',
        'content--left': data?.position === 'left'
    });

    return (
        <header className="header__dynamic" style={headerStyle}>
            <div className="container">
                <div className={headerInnerClasses}>
                    <img src={logo} alt="ESPN logo" />
                    <h1 className="heading heading--large heading--white">
                        {data?.title}
                    </h1>

                    <section className={headerContentClasses}>
                        {data?.content && parseMarkdownContent(data.content)}
                    </section>

                    <div className="button-container">
                        {data?.button?.map(button => (
                            <Button
                                key={button.id}
                                type={button.type}
                                link={button.url}
                                target={button.is_external ? '_blank' : '_self'}
                                label={button.label}
                                theme="dark"
                            />
                        ))}
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
