import './HighlightBlock.scss';

import { extractAssetURL } from '@api/utils';
import Image from '@components/ui/Image';
import classNames from 'classnames';

const HighlightBlock = ({ data, large }) => {
    const blockStyle = {
        backgroundImage: `url(${extractAssetURL(data?.background_image?.data?.attributes)})`
    };

    return (
        <div
            className={classNames('highlight-block', {
                'highlight-block--large': large
            })}
            style={blockStyle}
        >
            <div className="inner">
                <div className="text-container">
                    <h2 className="heading heading--medium heading--white">
                        {data.title}
                    </h2>

                    <p className="text text--white">{data.description}</p>
                </div>

                {data?.foreground_image?.data && (
                    <>
                        <div className="foreground-container">
                            <Image
                                image={data?.foreground_image?.data?.attributes}
                                alt={
                                    data?.foreground_image?.data?.attributes
                                        ?.name || 'Voorgrond afbeelding'
                                }
                            />
                        </div>
                        <div className="background" />
                    </>
                )}
            </div>
        </div>
    );
};

export default HighlightBlock;
