export const parseDateToLocalDatetime = dateTime => {
    if (!dateTime || Number.isNaN(new Date(dateTime))) return;

    const newDate = new Date(dateTime);
    const date = newDate.toLocaleDateString('nl-NL', {
        day: 'numeric',
        month: 'long'
    });
    const time = newDate.toLocaleTimeString('nl-NL', {
        hour: '2-digit',
        minute: '2-digit'
    });

    return `${date}, ${time}`;
};
