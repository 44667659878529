import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import './Goal.scss';

import { useEffect, useMemo } from 'react';

import Meta from '@components/meta/Meta';
import Image from '@components/ui/Image';
import useRedirect404 from '@hooks/useRedirect404';
import useStrapiQueries from '@hooks/useStrapiQueries';
import PageLayout from '@layouts/PageLayout';
import { pageView } from '@scripts/analytics';
import Slider from 'react-slick';
import Header from './blocks/Header';
import ImageCTABlock from './blocks/ImageCTABlock';

const SLIDER_CONFIG = {
    autoplay: true,
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2
            }
        },
        {
            breakpoint: 400,
            settings: {
                slidesToShow: 1
            }
        }
    ]
};

const Goal = () => {
    const { useFetchGoalPage } = useStrapiQueries();

    const {
        data: pageData,
        isLoading: pageDataLoading,
        error: pageDataError
    } = useFetchGoalPage();

    useRedirect404(pageDataLoading, pageDataError || pageData?.error);

    useEffect(() => {
        pageView('goal');
    }, []);

    const {
        meta,
        header,
        app_store_button,
        play_store_button,
        text_block,
        image_slider
    } = pageData?.data?.attributes || {};

    const headerContent = {
        background: header?.background,
        title: header?.title,
        subtitle: header?.subtitle,
        app_buttons: [
            {
                image: app_store_button?.image,
                link: app_store_button?.url,
                alt: 'Download de ESPN Goal! app in de App Store',
                track: 'campagne:download:apple-store'
            },
            {
                image: play_store_button?.image,
                link: play_store_button?.url,
                alt: 'Download de ESPN Goal! app in de Google Play Store',
                track: 'campagne:download:google-play'
            }
        ],
        ...header
    };

    const sortedSliderImages = useMemo(() => {
        if (!image_slider?.images?.data) return [];

        switch (image_slider.sort_by) {
            case 'random':
                return image_slider.images.data.sort(() => Math.random() - 0.5);

            case 'filename-ascending':
                return image_slider.images.data.sort((a, b) =>
                    a.attributes.name.localeCompare(b.attributes.name)
                );
            case 'filename-descending':
                return image_slider.images.data.sort((a, b) =>
                    b.attributes.name.localeCompare(a.attributes.name)
                );
            default:
                return image_slider.images.data;
        }
    }, [image_slider?.images?.data, image_slider?.sort_by]);

    return (
        <PageLayout id="goal">
            {meta && <Meta data={meta} />}
            {header && <Header data={headerContent} />}

            {text_block && <ImageCTABlock data={text_block} />}

            {sortedSliderImages?.length > 0 && (
                <div className="container slider-container">
                    <Slider {...SLIDER_CONFIG}>
                        {sortedSliderImages.map(image => (
                            <div key={image.id}>
                                <Image
                                    image={image?.attributes}
                                    className="img-responsive"
                                />
                            </div>
                        ))}
                    </Slider>
                </div>
            )}
        </PageLayout>
    );
};

export default Goal;
