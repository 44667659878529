import './SliderBlock.scss';

import ImageBlock from '../ImageBlock';

const Slide = ({ data }) => {
    return <ImageBlock data={data} />;
};

const SliderBlock = ({ data }) => {
    return (
        <section className="slider-block">
            <div className="container">
                <h2 className="title">{data?.title}</h2>

                <div className="slider">
                    {data?.blocks?.map(slide => (
                        <Slide key={slide.id} data={slide} />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default SliderBlock;
