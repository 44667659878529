import './CampaignRow.scss';

import CampaignSmall from './CampaignSmall';

const CampaignRow = ({ campaigns }) => {
    return (
        <section className="CampaignRow">
            <div className="container">
                <div className="block-wrapper">
                    {campaigns.map(campaign => (
                        <CampaignSmall campaign={campaign} key={campaign} />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default CampaignRow;
