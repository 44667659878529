import './Button.scss';

import { extractAssetURL } from '@api/utils';
import { trackLink } from '@scripts/analytics';
import classNames from 'classnames';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import Image from '../Image';

const ImageButton = ({
    className,
    link,
    target,
    onClick,
    track = false,
    image,
    alt
}) => {
    const classnames = classNames('image-button', className);

    const clickBtn = () => {
        if (track) {
            trackLink(track, link);
        }
        if (onClick) {
            onClick();
        }
    };

    const imageUrl = useMemo(() => extractAssetURL(image?.data), [image?.data]);
    const isHashLink = useMemo(() => link && link.charAt(0) === '#', [link]);

    const LinkComponent = isHashLink ? (
        <HashLink
            className={classnames}
            to={link}
            onClick={clickBtn}
            scroll={el => el.scrollIntoView({ behavior: 'smooth' })}
            target={target}
        >
            <img src={imageUrl} alt={alt} />
        </HashLink>
    ) : (
        <Link
            className={classnames}
            to={link}
            onClick={clickBtn}
            target={target}
        >
            {image && <Image image={image} alt={alt} />}
        </Link>
    );

    return LinkComponent;
};

export default ImageButton;
