import Abonneren from '@pages/Abonneren';
import Calendar from '@pages/Calendar';
import Download from '@pages/Download';
import Dynamic from '@pages/Dynamic';
import EspnOne from '@pages/EspnOne';
import FairPlay from '@pages/FairPlay';
import Frontpage from '@pages/Frontpage';
import Goal from '@pages/Goal';
import NotFound from '@pages/NotFound';
import Ontknoping from '@pages/Ontknoping';
import Partners from '@pages/Partners';
import Programmering from '@pages/Programmering';
import AppStore from 'pages/AppStore';
import GoalVideo from 'pages/Goal/Video/GoalVideo';

const routes = [
    {
        path: '',
        page: <Frontpage />
    },
    {
        path: '/download',
        page: <Download />
    },
    {
        path: '/espn1',
        page: <EspnOne />
    },
    {
        path: '/abonneren',
        page: <Abonneren />
    },
    {
        path: '/ontknoping',
        page: <Ontknoping />
    },
    {
        path: '/eerlijk-spel',
        page: <FairPlay />
    },
    {
        path: '/programmering',
        page: <Programmering />
    },
    {
        path: '/partners',
        page: <Partners />
    },
    {
        path: '/kalender',
        page: <Calendar />
    },
    {
        path: '/goal',
        page: <Goal />
    },
    {
        path: '/goal/video',
        page: <GoalVideo />
    },
    {
        path: '/goal/app-store',
        page: <AppStore />
    },
    {
        path: '/:slug',
        page: <Dynamic />
    },
    {
        path: '/404',
        page: <NotFound />
    }
];

export default routes;
