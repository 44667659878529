import './NotFound.scss';

import PageLayout from '@layouts/PageLayout';

const NotFound = () => {
    return (
        <PageLayout theme="dark" loading={false}>
            <div className="not-found-page">
                <h1>404</h1>
                <p>Oeps! Deze pagina bestaat niet.</p>
            </div>
        </PageLayout>
    );
};

export default NotFound;
