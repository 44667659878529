import './Partners.scss';

import Meta from '@components/meta/Meta';
import useRedirect404 from '@hooks/useRedirect404';
import useStrapiQueries from '@hooks/useStrapiQueries';
import PageLayout from '@layouts/PageLayout';
import { pageView } from '@scripts/analytics';
import { useEffect } from 'react';
import Header from './blocks/Header';
import PartnerBlock from './blocks/Partner';

const Partners = () => {
    const { useFetchPartnersPage } = useStrapiQueries();

    const {
        data: pageData,
        isLoading: pageLoading,
        error: pageError
    } = useFetchPartnersPage();

    useRedirect404(pageLoading, pageError || pageData?.error);

    useEffect(() => {
        pageView('partners');
    }, []);

    const { meta, header, partners, espn_partners } =
        pageData?.data?.attributes || {};

    return (
        <PageLayout loading={pageLoading}>
            {meta && <Meta data={meta} />}

            {header && <Header data={header} />}

            <section id="partners">
                <div className="container">
                    <section>
                        <ul className="partners-list">
                            {partners?.map(partner => (
                                <PartnerBlock key={partner.id} data={partner} />
                            ))}
                        </ul>
                    </section>
                </div>
            </section>
            <section id="espn-partners">
                <header className="section-header">
                    <div className="container">
                        <h2 className="heading heading--medium">
                            ESPN Partners
                        </h2>
                    </div>
                </header>
                <div className="container">
                    <section>
                        <ul className="partners-list">
                            {espn_partners?.map(partner => (
                                <PartnerBlock key={partner.id} data={partner} />
                            ))}
                        </ul>
                    </section>
                </div>
            </section>
        </PageLayout>
    );
};

export default Partners;
