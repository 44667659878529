import Footer from '@components/blocks/Footer';
import Navbar from '@components/ui/Navbar';
import Spinner from '@components/ui/Spinner';
import classNames from 'classnames';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const PageLayout = ({ id, theme, children, loading }) => {
    const layoutClass = classNames('page', {
        'page--dark': theme === 'dark'
    });

    const { pathname } = useLocation();

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <div id={id} className={layoutClass}>
            {loading ? (
                <Spinner size="page" />
            ) : (
                <>
                    <Navbar />
                    {children}
                    <Footer theme={theme} />
                </>
            )}
        </div>
    );
};

export default PageLayout;
