import './Providers.scss';

import { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { Button } from '@components/ui/Button';
import Image from '@components/ui/Image';
import useWindowDimensions from '@hooks/useWindowDimensions';

const Providers = ({ id, title, tiles }) => {
    const { viewWidth } = useWindowDimensions();
    const minHeight = viewWidth > 400 ? 160 : 320;
    const elRef = useRef();
    const [height, setHeight] = useState(minHeight);
    const [elHeight, setElHeight] = useState(0);
    const [expanded, setExpanded] = useState(false);

    useLayoutEffect(() => {
        const elementHeight =
            elRef.current.offsetHeight + elRef.current.style.marginTop;
        setElHeight(elementHeight);
    }, []);

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    const handleWindowResize = () => {
        setElHeight(elRef.current.clientHeight);
    };

    const toggleExpansion = () => {
        setHeight(expanded ? minHeight : elHeight);
        setExpanded(!expanded);
    };

    return (
        <section id={id} className="Providers">
            <div className="container">
                <h2 className="heading heading--white heading--medium text--center">
                    {title}
                </h2>
                <div className="container container--small">
                    <div
                        className="grid-container pt3 pb3"
                        style={{ height: `${height}px` }}
                    >
                        <div className="Providers__container" ref={elRef}>
                            {tiles?.map((tile, i) => (
                                <div className="Providers__item" key={tile}>
                                    {tile?.logo?.data && (
                                        <Image
                                            image={tile.logo?.data?.attributes}
                                            alt={`${tile.name} Logo`}
                                        />
                                    )}

                                    <p className="heading--extra-small heading--white">
                                        {tile.name}
                                    </p>

                                    {tile.text && (
                                        <span className="text text--white">
                                            {tile.text}
                                        </span>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                    <Button
                        className="mt2"
                        type="ghost"
                        label={
                            expanded
                                ? 'Minder tv-aanbieders'
                                : 'Overige tv-aanbieders'
                        }
                        size="large"
                        align="center"
                        onClick={toggleExpansion}
                    />
                </div>
            </div>
        </section>
    );
};

export default Providers;
