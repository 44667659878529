import API from '@api/api';
import qs from 'qs';

const populateConfig = {
    header: {
        populate: ['background', 'buttons']
    },
    section_2_button: {
        populate: true
    },
    section_3_image: {
        populate: true
    },

    meta: {
        populate: ['image']
    }
};

const fairPlayPageQueries = {
    getPageData: {
        key: 'fairPlayPageData',
        fetcher: async () => {
            const query = qs.stringify(
                {
                    populate: populateConfig
                },
                {
                    encodeValuesOnly: true // prettify URL
                }
            );

            return API.fetch(`/fair-play-page?${query}`);
        }
    }
};

export { fairPlayPageQueries };
