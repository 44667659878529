const DEFAULT_META = {
    meta_title: 'ESPN - Kijk alle Eredivisie wedstrijden live!',
    meta_description:
        'Wil je niks meer missen van de Eredivisie? Neem ESPN Compleet en kijk alle wedstrijden live.',
    og_title: 'ESPN - Kijk alle Eredivisie wedstrijden live!',
    og_description:
        'Wil je niks meer missen van de Eredivisie? Neem ESPN Compleet en kijk alle wedstrijden live.',
    og_type: 'website'
};

export default DEFAULT_META;
