const Plus = () => {
    return (
        <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <title>Plus icon</title>
            <rect x="11.25" y="3.5" width="1.5" height="18" fill="#121519" />
            <rect
                x="21"
                y="11.75"
                width="1.5"
                height="18"
                transform="rotate(90 21 11.75)"
                fill="currentColor"
            />
        </svg>
    );
};

export default Plus;
