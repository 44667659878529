import './HorizontalImageBlocks.scss';

import ImageBlock from '../../blocks/ImageBlock';

const HorizontalImageBlocks = ({ data }) => {
    return (
        <section id={data.id} className="image-blocks-horizontal">
            <div className="container">
                <h2 className="section-title">{data?.title}</h2>

                <div className="blocks">
                    {data?.blocks?.map(block => (
                        <ImageBlock key={block.id} data={block} />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default HorizontalImageBlocks;
