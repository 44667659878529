import { STRAPI_URL } from 'env';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const parseMarkdownContent = markdown => {
    return <Markdown remarkPlugins={[remarkGfm]}>{markdown}</Markdown>;
};

const extractAssetURL = asset => {
    const url = asset?.url;
    if (url) {
        return url.startsWith('http') ? url : `${STRAPI_URL}${url}`;
    }
};

export { extractAssetURL, parseMarkdownContent };
