import API from '@api/api';
import qs from 'qs';

const populateConfig = {
    header: {
        populate: ['background', 'buttons']
    },
    meta: {
        populate: ['image']
    },
    campaign_blocks: {
        populate: ['image']
    },
    cta: {
        populate: ['buttons', 'image']
    },
    banner: {
        populate: ['image']
    }
};

const frontPageQueries = {
    getFrontPageData: {
        key: 'frontPageData',
        fetcher: async () => {
            const query = qs.stringify(
                {
                    populate: populateConfig
                },
                {
                    encodeValuesOnly: true // prettify URL
                }
            );

            return API.fetch(`/frontpage?${query}`);
        }
    }
};

export { frontPageQueries };
