export const pageView = pageName => {
    if (!window.__dataLayer) return;
    if (!window._satellite) return;

    window.__dataLayer = {
        event: 'pageview',
        page_country: 'NL',
        brand_name: 'espn',
        page_environment: '#{ENV}#',
        page_language: 'NL',
        page_path: window.location.pathname,
        page_title: document.querySelector('title').innerHTML,
        page_market: 'NL',
        page_type: 'Home',
        page_name: `emea:nl:espn-campaign:${pageName}`,
        datalayer_version: 0.1
    };

    window._satellite.track(window.__dataLayer.event, window.__dataLayer);
};

export const trackLink = (buttonName, href) => {
    if (!window.__dataLayer) return;
    if (!window._satellite) return;

    window.__dataLayer.event = 'generic_linkclick';
    window.__dataLayer.link_name = buttonName;
    window.__dataLayer.link_url = href;

    window._satellite.track(window.__dataLayer.event, window.__dataLayer);
};
