import './Button.scss';

import { trackLink } from '@scripts/analytics';
import classNames from 'classnames';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const Arrow = () => (
    <svg
        className="svg-arrow"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
    >
        <title>Arrow icon</title>
        <path
            fill="currentColor"
            d="M11.9571 2.55078L19.3308 9.92455L11.9571 17.2984L10.5429 15.8842L15.5024 10.9246H2.49994V8.92455H15.5024L10.5429 3.96499L11.9571 2.55078Z"
        />
    </svg>
);

const Button = ({
    className,
    label,
    link,
    target,
    type,
    size,
    align,
    radius = 'rounded',
    onClick,
    track = false,
    showArrow = false,
    theme = 'dark'
}) => {
    const classnames = classNames('Button', className, {
        dark: theme === 'dark',
        light: theme === 'light',
        Button__red: type === 'red',
        Button__primary: type === 'primary',
        Button__secondary: type === 'secondary',
        Button__tertiary: type === 'tertiary',
        Button__ghost: type === 'ghost',
        'Button--large': size === 'large',
        'Button--center': align === 'center',
        'Button--rounded': radius === 'rounded',
        'Button--squared': radius === 'squared'
    });

    const clickBtn = () => {
        if (track) {
            trackLink(track, link);
        }
        if (onClick) {
            onClick();
        }
    };

    const isHashLink = useMemo(() => link && link.charAt(0) === '#', [link]);

    const LinkComponent = isHashLink ? (
        <HashLink
            className={classnames}
            to={link}
            onClick={clickBtn}
            scroll={el => el.scrollIntoView({ behavior: 'smooth' })}
            target={target}
        >
            {label}
            {showArrow && <Arrow />}
        </HashLink>
    ) : (
        <Link
            className={classnames}
            to={link}
            onClick={clickBtn}
            target={target}
        >
            {label}
            {showArrow && <Arrow />}
        </Link>
    );

    return LinkComponent;
};

export default Button;
