import './Header.scss';

import { extractAssetURL } from '@api/utils';
import { ImageButton } from '@components/ui/Button';
import Image from '@components/ui/Image';

const Header = ({ headerContent, summaryContent }) => {
    const headerImageUrl = extractAssetURL(
        headerContent?.image?.data?.attributes
    );

    const headerStyle = headerImageUrl
        ? {
              backgroundImage: `linear-gradient(#000 0%, #0000 83.44%), url(${headerImageUrl})`
          }
        : {};

    return (
        <header className="header__download" style={headerStyle}>
            <div className="container text--center">
                <h1 className="heading heading--large heading--white">
                    {headerContent.title}
                </h1>

                <h2 className="heading heading--extra-small heading--white mt2">
                    {headerContent.subtitle}
                </h2>

                {headerContent.buttons && (
                    <div className="buttons mt3 pb3">
                        {headerContent.buttons.map(button => (
                            <ImageButton
                                key={button}
                                alt={button.alt}
                                link={button.link}
                                track={button.track}
                                image={button.image?.data?.attributes}
                            />
                        ))}
                    </div>
                )}

                <div className="content container text--left">
                    <div className="row">
                        <div className="col">
                            <h2 className="heading heading--medium">
                                {summaryContent.title}
                            </h2>
                            <p className="text">{summaryContent.description}</p>
                        </div>
                        <div className="col">
                            <Image
                                image={summaryContent?.image?.data?.attributes}
                                alt="ESPN app on phone"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
