import './ExpandableGrid.scss';

import { useEffect, useMemo, useRef, useState } from 'react';

import { Button } from '@components/ui/Button';
import Tile from './Tile';
import useWindowDimensions from '@hooks/useWindowDimensions';

const ExpandableGrid = ({ id, data, logoClickEvent, disableExpand }) => {
    const { viewWidth } = useWindowDimensions();
    const minHeight = viewWidth > 400 ? 160 : 320;
    const elRef = useRef();
    const [height, setHeight] = useState(
        disableExpand ? 'initial' : `${minHeight}px`
    );
    const [elHeight, setElHeight] = useState(disableExpand ? 400 : 0);
    const [expanded, setExpanded] = useState(disableExpand ? true : false);

    const handleWindowResize = () => {
        setElHeight(elRef.current.clientHeight);
    };

    useEffect(() => {
        const resizeObserver = new ResizeObserver(() => {
            const elementHeight =
                elRef.current.offsetHeight + elRef.current.style.marginTop;
            setElHeight(elementHeight);
        });
        if (elRef.current) {
            resizeObserver.observe(elRef.current);
        }
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
            resizeObserver.disconnect();
        };
    }, [handleWindowResize]);

    const toggleExpansion = () => {
        setHeight(expanded ? minHeight : elHeight);
        setExpanded(!expanded);
    };

    const tiles = useMemo(() => {
        return data?.providers
            ?.filter(provider => provider.show)
            ?.map(tile => {
                return {
                    ...tile,
                    logoClickEvent
                };
            });
    }, [data, logoClickEvent]);

    return (
        <section id={id} className="ExpandableGrid">
            <div className="container">
                <h2 className="heading heading--white heading--medium text--center">
                    {data?.title}
                </h2>
                <div className="container container--small">
                    <div
                        className="grid-container pt3 pb3"
                        style={{ height: height }}
                    >
                        <div className="tiles" ref={elRef}>
                            {tiles?.map(tile => (
                                <Tile
                                    tile={tile}
                                    logoClickEvent={logoClickEvent}
                                    key={`tile-${tile.name}`}
                                />
                            ))}
                        </div>
                    </div>

                    {!disableExpand && (
                        <Button
                            className="mt2"
                            type="secondary"
                            label={
                                expanded
                                    ? 'Minder tv-aanbieders'
                                    : 'Overige tv-aanbieders'
                            }
                            size="large"
                            align="center"
                            onClick={toggleExpansion}
                        />
                    )}
                </div>
            </div>
        </section>
    );
};

export default ExpandableGrid;
