import { useEffect, useMemo } from 'react';

import matchesVisual from '@assets/images/espn1-matches1.webp';
import Meta from '@components/meta/Meta';
import useRedirect404 from '@hooks/useRedirect404';
import useStrapiQueries from '@hooks/useStrapiQueries';
import PageLayout from '@layouts/PageLayout';
import { pageView } from '@scripts/analytics';
import MatchesList from 'components/lists/MatchesList';
import Header from './blocks/Header';
import Providers from './blocks/Providers';

const EspnOne = () => {
    const { useFetchESPNOnePage } = useStrapiQueries();
    const {
        data: pageData,
        isLoading: pageDataLoading,
        error: pageDataError
    } = useFetchESPNOnePage();

    useEffect(() => {
        pageView('espnOne');
    }, []);

    useRedirect404(pageDataLoading, pageDataError || pageData?.error);

    const { meta, header, matches_title, providers } =
        pageData?.data?.attributes || {};

    const headerMemo = useMemo(() => header, [header]);

    return (
        <PageLayout loading={pageDataLoading}>
            {meta && <Meta data={meta} />}
            {headerMemo && <Header data={headerMemo} />}

            <MatchesList
                bgImage={matchesVisual}
                title={matches_title}
                channel="ESPN 1"
            />

            <Providers
                title={providers?.title}
                id="providers"
                tiles={providers?.providers}
            />
        </PageLayout>
    );
};

export default EspnOne;
