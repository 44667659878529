import { extractAssetURL } from '@api/utils';
import { Helmet } from 'react-helmet';

const Meta = ({ data, children }) => {
    return (
        <Helmet>
            <title>{data?.meta_title}</title>
            <meta property="og:url" name="og:url" content={data?.og_url} />
            <meta property="og:locale" name="og:locale" content="nl_NL" />
            <meta
                property="description"
                name="description"
                content={data?.meta_description}
            />
            <meta
                name="og:title"
                property="og:title"
                content={data?.og_title}
            />
            <meta
                name="og:description"
                property="og:description"
                content={data?.og_description}
            />
            <meta name="og:type" property="og:type" content="website" />
            <meta
                name="og:site_name"
                property="og:site_name"
                content="ESPN.nl"
            />
            <meta
                property="twitter:title"
                name="twitter:title"
                content={data?.meta_title}
            />

            <meta
                property="twitter:description"
                name="twitter:description"
                content={data?.meta_description}
            />
            <meta
                property="twitter:image"
                name="twitter:image"
                content={extractAssetURL(data?.image?.data?.attributes)}
            />
            {data?.image?.data?.attributes && (
                <meta
                    name="og:image"
                    property="og:image"
                    content={extractAssetURL(data?.image?.data?.attributes)}
                />
            )}

            {children}
        </Helmet>
    );
};

export default Meta;
