import './GoalHeader.scss';

import FallbackBackground from '../../../../assets/images/goal-fallback-banner.jpg';
import getClubLogo from '../../../../utils/getClubLogo';

const GoalHeader = ({ data }) => {
    const { background, title, teams, gameMinute, date } = data;

    const headerStyle = {
        backgroundImage: background
            ? `linear-gradient(to right, #000, #0000 100%), url(${background})`
            : `linear-gradient(11.33deg, rgba(0, 0, 0, 0.78) 30.32%, rgba(0, 0, 0, 0) 98.84%, rgba(0, 0, 0, 0.7) 112.99%), url(${FallbackBackground})`
    };

    const clubLogo = (url, name) => {
        if (url?.includes('undefined')) {
            return getClubLogo(name);
        }
        return url;
    };

    return (
        <header className="header__goalvideo" style={headerStyle}>
            <div className="container">
                <div className="container--inner">
                    <div className="logo-container">
                        <div className="team-wrapper">
                            <img
                                src={clubLogo(
                                    teams?.home?.logo?.smallUrl,
                                    teams?.home?.name
                                )}
                                alt={teams?.home?.name}
                            />
                            <h4 className="heading heading--small heading--white">
                                {teams?.home?.name}
                            </h4>
                        </div>
                        <h3 className="goal heading heading--large heading--white">
                            GOAL!
                        </h3>

                        <div className="team-wrapper">
                            <img
                                src={clubLogo(
                                    teams?.away?.logo?.smallUrl,
                                    teams?.away?.name
                                )}
                                alt={teams?.away?.name}
                            />
                            <h4 className="heading heading--small heading--white">
                                {teams?.away?.name}
                            </h4>
                        </div>
                    </div>
                    <h1 className="title heading heading--large heading--white">
                        {title}
                    </h1>

                    <div className="meta-container">
                        {gameMinute && <span>{gameMinute}'</span>}
                        {gameMinute && date && <span> | </span>}
                        {date && <span>{date}</span>}
                    </div>
                </div>
            </div>
        </header>
    );
};

export default GoalHeader;
