import Meta from '@components/meta/Meta';
import useRedirect404 from '@hooks/useRedirect404';
import useStrapiQueries from '@hooks/useStrapiQueries';
import PageLayout from '@layouts/PageLayout';
import { pageView } from '@scripts/analytics';
import { useEffect } from 'react';
import Header from './blocks/Header';
import ImageCTABlock from './blocks/ImageCTABlock';

const Programmering = () => {
    const { useFetchProgrammeringPage } = useStrapiQueries();

    const {
        data: pageData,
        isLoading: pageDataLoading,
        error: pageDataError
    } = useFetchProgrammeringPage();

    useRedirect404(pageDataLoading, pageDataError || pageData?.error);

    useEffect(() => {
        pageView('programmering');
    }, []);

    const { meta, header, blocks } = pageData?.data?.attributes || {};

    return (
        <PageLayout>
            {meta && <Meta data={meta} />}

            {header && <Header data={header} />}

            <section id="programs">
                {blocks?.map(block => (
                    <ImageCTABlock data={block} key={block.id} screen />
                ))}
            </section>
        </PageLayout>
    );
};

export default Programmering;
