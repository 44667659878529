import './CampaignBig.scss';

import Image from '@components/ui/Image';
import { Link } from 'react-router-dom';

const CampaignBig = ({ title, description, link, image, link_label }) => {
    return (
        <section className="CampaignBig">
            <div className="container">
                <div className="block-wrapper">
                    <div className="block">
                        <div className="d-flex">
                            <div className="col content">
                                <span className="block__label text">
                                    Campagne
                                </span>
                                <h1 className="heading heading--large heading--white">
                                    {title}
                                </h1>
                                <Link className="text plain" to={link}>
                                    {description}
                                </Link>
                                <Link className="text arrow" to={link}>
                                    {link_label}
                                    <svg
                                        className="svg-arrow"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <title>Arrow icon</title>
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M11.9571 2.55078L19.3308 9.92455L11.9571 17.2984L10.5429 15.8842L15.5024 10.9246H2.49994V8.92455H15.5024L10.5429 3.96499L11.9571 2.55078Z"
                                        />
                                    </svg>
                                </Link>
                            </div>
                            <div className="col image">
                                <Image image={image?.data?.attributes} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CampaignBig;
