import API from '@api/api';
import qs from 'qs';

const populateConfig = {
    logo: true
};

const clubsQueries = {
    getClubsData: {
        key: 'clubsData',
        fetcher: async () => {
            const query = qs.stringify(
                {
                    populate: populateConfig
                },

                {
                    encodeValuesOnly: true // prettify URL
                }
            );

            return API.fetch(`/clubs?${query}`);
        }
    }
};

export { clubsQueries };
