import './TextBlock.scss';

import classNames from 'classnames';

const TextBlock = ({ data }) => {
    const containerClassNames = classNames('container', {
        center: data?.position === 'center',
        right: data?.position === 'right',
        left: data?.position === 'left'
    });

    return (
        <section className="text-block">
            <div className={containerClassNames}>
                <h2 className="title">{data?.title}</h2>
            </div>
        </section>
    );
};

export default TextBlock;
