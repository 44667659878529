import React from 'react';

import MatchesList from 'components/lists/MatchesList';

const MatchOverviewBlock = ({ data }) => {
    const { theme, competition, title } = data;
    if (!data || !competition) return null;

    return (
        <MatchesList title={title} theme={theme} competition={competition} />
    );
};

export default MatchOverviewBlock;
