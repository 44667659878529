import './Textblock.scss';

import { Button } from '@components/ui/Button';
import Image from '@components/ui/Image';
import { BlocksRenderer } from '@strapi/blocks-react-renderer';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

const BLOCKS_CONFIG = {
    heading: ({ children, level }) => {
        switch (level) {
            case 1:
                return (
                    <h1 className="heading mb2 mt8 heading--large">
                        {children}
                    </h1>
                );
            case 2:
                return (
                    <h2 className="heading mb2 mt8 heading--medium">
                        {children}
                    </h2>
                );
            case 3:
                return (
                    <h3 className="heading mb2 mt8 heading--small">
                        {children}
                    </h3>
                );
            case 4:
                return (
                    <h4 className="heading mb2 mt8 heading----extra-small">
                        {children}
                    </h4>
                );
            case 5:
                return <h5 className="heading mb2 mt8">{children}</h5>;
            case 6:
                return <h6 className="heading mb2 mt8">{children}</h6>;
            default:
                return <b className="heading mb2 mt8">{children}</b>;
        }
    },
    paragraph: ({ children }) => <p className="text mb2">{children}</p>,
    link: ({ children, url }) => (
        <Link target="_blank" rel="noopener noreferrer" to={url}>
            {children}
        </Link>
    )
};

const Textblock = ({ content, useBlockRenderer = false, id, className }) => {
    const textblockClass = classNames('fairplay-textblock', className);
    const layoutClass = classNames('wrapper', {
        row: content.image
    });

    return (
        <section id={id} className={textblockClass}>
            <div className="container">
                {useBlockRenderer ? (
                    <BlocksRenderer blocks={BLOCKS_CONFIG} content={content} />
                ) : (
                    <div className={layoutClass}>
                        <div className="text-col">
                            <h2 className="heading heading--medium mb2">
                                {content.title}
                            </h2>
                            <p className="text">{content.description}</p>
                            {content.button && (
                                <Button
                                    radius="rounded"
                                    type="primary"
                                    link={content.button.url}
                                    target={
                                        content.button.is_external
                                            ? '_blank'
                                            : '_self'
                                    }
                                    label={content.button.label}
                                    showArrow
                                    theme="light"
                                />
                            )}
                        </div>
                        {content?.image?.data && (
                            <div className="image-col">
                                <Image image={content.image.data?.attributes} />
                            </div>
                        )}
                    </div>
                )}
            </div>
        </section>
    );
};

export default Textblock;
