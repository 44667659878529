import { useEffect, useMemo } from 'react';

import Meta from '@components/meta/Meta';
import useRedirect404 from '@hooks/useRedirect404';
import useStrapiQueries from '@hooks/useStrapiQueries';
import PageLayout from '@layouts/PageLayout';
import { pageView } from '@scripts/analytics';
import Header from './blocks/Header';
import Textblock from './blocks/Textblock';

const FairPlay = () => {
    const { useFetchFairPlayPage } = useStrapiQueries();

    const {
        data: pageData,
        isLoading: pageDataLoading,
        error: pageDataError
    } = useFetchFairPlayPage();

    useRedirect404(pageDataLoading, pageDataError || pageData?.error);

    useEffect(() => {
        pageView('eerlijk-spel');
    }, []);

    const {
        meta,
        header,
        section_1,
        section_2_title,
        section_2_description,
        section_2_button,
        section_3_title,
        section_3_description,
        section_3_image,
        section_4
    } = pageData?.data?.attributes || {};

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    const pageSections = useMemo(() => {
        return {
            section1: section_1,
            section2: {
                title: section_2_title,
                description: section_2_description,
                button: section_2_button
            },
            section3: {
                title: section_3_title,
                description: section_3_description,
                image: section_3_image
            },
            section4: section_4
        };
    }, [pageData?.data?.attributes]);

    return (
        <PageLayout>
            {meta && <Meta data={meta} />}

            {header && <Header data={header} />}
            {pageSections.section1 && (
                <Textblock useBlockRenderer content={pageSections.section1} />
            )}
            {pageSections.section2 && (
                <Textblock content={pageSections.section2} />
            )}
            {pageSections.section3 && (
                <Textblock content={pageSections.section3} />
            )}
            {pageSections.section4 && (
                <Textblock useBlockRenderer content={pageSections.section4} />
            )}
        </PageLayout>
    );
};

export default FairPlay;
