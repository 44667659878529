// hook that returns the proper app store url based on the deviceType

import { useEffect, useState } from 'react';

import AndroidIcon from '../assets/icons/Other/android.svg';
import iOSIcon from '../assets/icons/Other/apple.svg';
import useDeviceType from './useDeviceType';

const APP_STORE = {
    ios: {
        appUrl: 'https://apps.apple.com/nl/app/espn-goal/id6472048722',
        iconAlt: 'Apple App Store icoon',
        icon: iOSIcon
    },
    android: {
        appUrl: 'https://play.google.com/store/apps/details?id=com.espn.goalalert',
        iconAlt: 'Google Play Store icoon',
        icon: AndroidIcon
    },
    other: {
        appUrl: '/goal',
        iconAlt: null,
        icon: null
    }
};

const useAppStore = () => {
    const deviceType = useDeviceType();
    const [appStore, setAppStore] = useState(APP_STORE.other);

    useEffect(() => {
        if (deviceType === 'ios') {
            setAppStore(APP_STORE.ios);
        }

        if (deviceType === 'android') {
            setAppStore(APP_STORE.android);
        }

        if (deviceType === 'unknown') {
            setAppStore(APP_STORE.other);
        }
    }, [deviceType]);

    return appStore;
};

export default useAppStore;
