import { useEffect, useMemo } from 'react';

import Meta from '@components/meta/Meta';
import useRedirect404 from '@hooks/useRedirect404';
import useStrapiQueries from '@hooks/useStrapiQueries';
import PageLayout from '@layouts/PageLayout';
import { pageView } from '@scripts/analytics';
import { atcb_action } from 'add-to-calendar-button';
import Header from './blocks/Header';
import TabbedContent from './blocks/TabbedContent';

const CALENDAR_CONFIG = {
    subscribe: true,
    options: [
        'Apple',
        'Google',
        'Microsoft365',
        'Outlook.com',
        'Yahoo',
        'iCal'
    ],
    timeZone: 'Europe/Amsterdam',
    startDate: '2023-01-01'
};

const Calendar = () => {
    const { useFetchCalendarPage, useFetchCompetitions } = useStrapiQueries();

    const {
        data: pageData,
        isLoading: pageDataLoading,
        error: pageDataError
    } = useFetchCalendarPage();

    const {
        data: competitionsData,
        isLoading: competitionsDataLoading,
        error: competitionsDataError
    } = useFetchCompetitions();

    useRedirect404(pageDataLoading, pageDataError || pageData?.error);

    const { meta, header } = pageData?.data?.attributes || {};

    const tabData = useMemo(() => {
        return competitionsData?.data?.map(competition => {
            return {
                name: competition?.attributes?.name,
                img: competition?.attributes?.logo?.data?.attributes,
                content: competition?.attributes?.clubs?.data?.map(club => {
                    return {
                        name: club?.attributes?.name?.trim(),
                        img: club?.attributes?.logo?.data?.attributes,
                        onclick: () => {
                            atcb_action({
                                ...CALENDAR_CONFIG,
                                name: `${club?.attributes?.name?.trim()} wedstrijden`,
                                description: `Volg de wedstrijden van ${club?.attributes?.name?.trim()}. Mogelijk gemaakt door https://espn.nl`,
                                icsFile: `https://${window.location.host}/ics/${club?.attributes?.name?.trim()}.ics`
                            });
                        }
                    };
                })
            };
        });
    }, [competitionsData]);

    useEffect(() => {
        pageView('kalender');
    }, []);

    const pageLoading = pageDataLoading || competitionsDataLoading;

    return (
        <PageLayout loading={pageLoading}>
            {meta && <Meta data={meta} />}

            {header && <Header data={header} />}

            <div className="container">
                {competitionsDataError ? (
                    <b>Fout bij het ophalen van de competities.</b>
                ) : (
                    <TabbedContent tabs={tabData} />
                )}
            </div>
        </PageLayout>
    );
};

export default Calendar;
