import { useQuery } from '@tanstack/react-query';
import { goalPageQueries } from 'api/queries';

const useGoalsAPI = ({ matchId, mediaId }) => {
    const {
        data: goalAPIData,
        isLoading: goalAPILoading,
        error: goalAPIError
    } = useQuery({
        queryKey: [goalPageQueries.getGoalData.key],
        queryFn: () => goalPageQueries.getGoalData.fetcher({ matchId, mediaId })
    });

    return { goalAPIData, goalAPILoading, goalAPIError };
};

export { useGoalsAPI };
