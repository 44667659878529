import './VideoBlock.scss';

import { extractAssetURL } from '@api/utils';

const VideoBlock = ({ data }) => {
    const videoCover = extractAssetURL(data?.cover?.data?.attributes);
    const videoFile = extractAssetURL(data?.video?.data?.attributes);
    const videoFileType = data?.video?.data?.attributes?.mime;

    return (
        <section className="video-block">
            <div className="container">
                {videoFile && (
                    <video
                        controls
                        className="video-element"
                        poster={videoCover}
                    >
                        <track kind="captions" />
                        <source src={videoFile} type={videoFileType} />
                    </video>
                )}
            </div>
        </section>
    );
};

export default VideoBlock;
