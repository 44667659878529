import API from '@api/api';
import qs from 'qs';

const matchesQueries = {
    getMatchesData: {
        key: 'matchesData',
        fetcher: async ({ filter = {}, sort = 'date:asc' }) => {
            const query = qs.stringify(
                {
                    populate: true,
                    filters: filter,
                    sort: sort
                },

                {
                    encodeValuesOnly: true // prettify URL
                }
            );

            return API.fetch(`/matches?${query}`);
        }
    }
};

export { matchesQueries };
