import API from '@api/api';
import qs from 'qs';

const populateConfig = {
    logo: true,
    lists: {
        populate: {
            link: {
                populate: '*'
            }
        }
    },
    small_links: {
        populate: '*'
    }
};

const navigationQueries = {
    getFooterData: {
        key: 'footerData',
        fetcher: async () => {
            const query = qs.stringify(
                {
                    populate: populateConfig
                },
                {
                    encodeValuesOnly: true // prettify URL
                }
            );

            return API.fetch(`/footer?${query}`);
        }
    }
};

export { navigationQueries };
