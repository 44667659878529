import './Footer.scss';

import logoRed from '@assets/images/logo-espn-red.svg';
import useStrapiQueries from '@hooks/useStrapiQueries';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Image from '../../ui/Image';

const Footer = ({ theme }) => {
    const { useFetchFooterData } = useStrapiQueries();
    const { data: footerData } = useFetchFooterData();

    const { copyright, lists, logo, small_links } =
        footerData?.data?.attributes || {};

    const footerClass = classNames('Footer', {
        'Footer--dark': theme === 'dark'
    });

    const iconType = link => {
        if (theme === 'dark') {
            return link.icon_dark?.data ?? link.icon?.data;
        }
        return link.icon?.data ?? null;
    };

    return (
        <footer className={footerClass}>
            <div className="container">
                <a className="logo" href="https://espn.nl">
                    <img
                        width="73px"
                        height="18px"
                        src={logoRed}
                        alt="ESPN logo"
                    />
                </a>

                <div className="wrapper">
                    {lists?.map(list => (
                        <section
                            className="Footer__list competitions"
                            key={list.id}
                        >
                            <h2 className="heading heading--extra-small">
                                {list.title}
                            </h2>
                            <ul className="list">
                                {list?.link?.map(link => (
                                    <li className="list__item" key={link.id}>
                                        <Link
                                            className="footer-link"
                                            to={link.url}
                                            rel="noreferrer noopener"
                                            target={
                                                link.is_external
                                                    ? '_blank'
                                                    : '_self'
                                            }
                                        >
                                            {iconType(link) && (
                                                <Image
                                                    size="thumbnail"
                                                    style={{
                                                        width: '20px',
                                                        height: '20px'
                                                    }}
                                                    image={
                                                        iconType(link)
                                                            ?.attributes
                                                    }
                                                    alt={`Logo van ${link.label}`}
                                                />
                                            )}

                                            {link.label}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </section>
                    ))}
                </div>
            </div>
            <section className="Footer__bottom">
                {logo?.data && (
                    <Image
                        image={logo.data.attributes}
                        alt="Logo van ESPN"
                        className="Footer__logo"
                    />
                )}

                {copyright && <p>© {copyright}</p>}

                {small_links && (
                    <div className="Footer__links">
                        {small_links?.link?.map(link => (
                            <Link
                                to={link.url}
                                key={link.id}
                                target={link.is_external ? '_blank' : '_self'}
                                rel="noreferrer noopener"
                            >
                                {link.label}
                            </Link>
                        ))}
                        <Link
                            className="ot-sdk-show-settings"
                            to="/#"
                            rel="noreferrer noopener"
                        >
                            Manage Privacy Preferences
                        </Link>
                    </div>
                )}
            </section>
        </footer>
    );
};

export default Footer;
