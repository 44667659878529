import './Header.scss';

import { extractAssetURL, parseMarkdownContent } from '@api/utils';
import { Button, ImageButton } from '@components/ui/Button';

import classNames from 'classnames';

const Header = ({ data }) => {
    const headerStyle = {
        backgroundImage: `url(${extractAssetURL(data?.background?.data?.attributes)})`
    };

    const headerInnerClasses = classNames('container--inner', {
        'text--center': data?.position === 'center',
        'text--right': data?.position === 'right',
        'text--left': data?.position === 'left'
    });

    const headerContentClasses = classNames('content', {
        'content--center': data?.position === 'center',
        'content--right': data?.position === 'right',
        'content--left': data?.position === 'left'
    });

    return (
        <header className="header__goal" style={headerStyle}>
            <div className="container">
                <div className={headerInnerClasses}>
                    <h1 className="heading heading--large heading--white">
                        {data?.title}
                    </h1>

                    {data?.content && (
                        <section className={headerContentClasses}>
                            {parseMarkdownContent(data.content)}
                        </section>
                    )}

                    {data?.buttons?.length > 0 && (
                        <div className="button-container">
                            {data?.buttons?.map(button => (
                                <Button
                                    key={button}
                                    type={button.type}
                                    link={button.url}
                                    target={
                                        button.is_external ? '_blank' : '_self'
                                    }
                                    label={button.label}
                                    theme="dark"
                                />
                            ))}
                        </div>
                    )}

                    {data?.app_buttons?.length > 0 && (
                        <div className="button-container">
                            {data?.app_buttons?.map(button => (
                                <ImageButton
                                    key={button}
                                    alt={button.alt}
                                    link={button.link}
                                    track={button.track}
                                    image={button.image?.data?.attributes}
                                />
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </header>
    );
};

export default Header;
