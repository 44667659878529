import './MatchesList.scss';

import Spinner from '@components/ui/Spinner';
import useStrapiQueries from 'hooks/useStrapiQueries';
import { parseDateToLocalDatetime } from 'utils/date.utils';
import getClubLogo from 'utils/getClubLogo';

const MatchesList = ({
    bgImage,
    title,
    channel,
    competition = 'Eredivisie',
    theme = 'light'
}) => {
    const filterObject = () => {
        let filter = {};

        if (competition && competition !== 'All') {
            filter.competition = { $containsi: competition };
        }
        if (channel) {
            filter.channel = { $containsi: channel };
        }

        return filter;
    };

    const { useFetchMatchesData, useFetchClubsData } = useStrapiQueries();
    const { data: matchesData, isLoading: matchesLoading } =
        useFetchMatchesData({
            filter: filterObject()
        });
    const { data: clubsData, isLoading: clubsLoading } = useFetchClubsData();

    const styles = {
        backgroundImage: bgImage ? `url(${bgImage})` : 'none'
    };

    const determineClubLogo = clubName => {
        const club = clubsData?.data.find(
            club => club.attributes.name === clubName
        );

        return (
            club?.attributes?.logo?.data?.attributes?.url ??
            getClubLogo(clubName)
        );
    };

    const loading = matchesLoading || clubsLoading;

    return (
        <section id="matches" className="Matches" style={styles}>
            <div className="container">
                {loading ? (
                    <Spinner size="block" label={'Matches worden geladen...'} />
                ) : (
                    <>
                        <h2
                            className={`heading heading--medium ${theme === 'light' ? 'heading--white' : 'heading--black'} text--center`}
                        >
                            {title}
                        </h2>
                        <div className="Matches__featured">
                            {matchesData?.data?.map(match => {
                                const { attributes, id } = match;

                                return (
                                    <div className="Matches__row" key={id}>
                                        <div
                                            className={`Matches__date text ${theme === 'light' ? 'text--white' : 'text--black'}`}
                                        >
                                            {parseDateToLocalDatetime(
                                                attributes?.date
                                            )}
                                        </div>
                                        {attributes?.channel && (
                                            <div
                                                className={`Matches__channel text ${theme === 'light' ? 'text--white' : 'text--black'}`}
                                            >
                                                {attributes?.channel}
                                            </div>
                                        )}

                                        <div className="Matches__clubs">
                                            <div
                                                className={`Matches__home text ${theme === 'light' ? 'text--white' : 'text--black'}`}
                                            >
                                                {attributes?.club_a}
                                                <img
                                                    className="Matches__logo"
                                                    src={determineClubLogo(
                                                        attributes?.club_a
                                                    )}
                                                    alt={attributes?.club_a}
                                                />
                                            </div>
                                            <div
                                                className={`text ${theme === 'light' ? 'text--white' : 'text--black'}`}
                                            >
                                                -
                                            </div>
                                            <div
                                                className={`Matches__away text ${theme === 'light' ? 'text--white' : 'text--black'}`}
                                            >
                                                <img
                                                    className="Matches__logo"
                                                    src={determineClubLogo(
                                                        attributes?.club_b
                                                    )}
                                                    alt={attributes?.club_b}
                                                />
                                                {attributes?.club_b}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </>
                )}
            </div>
        </section>
    );
};

export default MatchesList;
