import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import '../Goal.scss';

import { useEffect, useMemo } from 'react';

import Meta from '@components/meta/Meta';
import Image from '@components/ui/Image';
import useRedirect404 from '@hooks/useRedirect404';
import useStrapiQueries from '@hooks/useStrapiQueries';
import PageLayout from '@layouts/PageLayout';
import { pageView } from '@scripts/analytics';
import { useGoalsAPI } from 'hooks/useGoalsAPI';
import { useSearchParams } from 'react-router-dom';
import Slider from 'react-slick';
import AppStoreBanner from '../blocks/AppStoreBanner';
import GoalHeader from '../blocks/GoalHeader';
import Header from '../blocks/Header';
import ImageCTABlock from '../blocks/ImageCTABlock';
import QRCodeBlock from '../blocks/QRCodeBlock';

const SLIDER_CONFIG = {
    autoplay: true,
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2
            }
        },
        {
            breakpoint: 400,
            settings: {
                slidesToShow: 1
            }
        }
    ]
};

const GoalVideo = () => {
    const [params] = useSearchParams();
    const { useFetchGoalPage } = useStrapiQueries();
    const { goalAPIData, goalAPIError, goalAPILoading } = useGoalsAPI({
        matchId: params.get('matchId'),
        mediaId: params.get('mediaId')
    });

    const {
        data: pageData,
        isLoading: pageDataLoading,
        error: pageDataError
    } = useFetchGoalPage();

    const loading = pageDataLoading || goalAPILoading;

    useRedirect404(
        pageDataLoading,
        pageDataError || pageData?.error || goalAPIError
    );

    useEffect(() => {
        pageView('goal');
    }, []);

    const {
        header,
        app_store_button,
        play_store_button,
        text_block,
        image_slider
    } = pageData?.data?.attributes || {};

    const metaContent = useMemo(() => {
        const { media } = goalAPIData || {};

        const meta = {
            meta_title:
                media?.title || 'ESPN GOAL! – Alle goals in je broekzak',
            meta_description: 'Bekijk dit doelpunt direct!',
            og_title: media?.title || 'ESPN GOAL! – Alle goals in je broekzak',
            og_site_name: 'ESPN - GOAL!',
            og_locale: 'nl_NL',
            og_description: 'Bekijk dit doelpunt direct!',
            og_url: window.location.href,
            image:
                media?.thumbnail?.largeUrl ||
                `${window.location.origin}/img/goal-share-socials.png`,
            twitter_title:
                media?.title || 'ESPN GOAL! – Alle goals in je broekzak',
            twitter_description: 'Bekijk dit doelpunt direct!'
        };

        return meta;
    }, [goalAPIData]);

    const goalHeaderContent = useMemo(() => {
        const { match, media } = goalAPIData || {};
        return {
            date: match?.date
                ? new Intl.DateTimeFormat(['nl-NL', 'en-GB'], {
                      day: 'numeric',
                      month: 'short',
                      year: 'numeric'
                  }).format(match?.date)
                : null,
            gameMinute: media?.minute,
            background: media?.thumbnail?.largeUrl,
            title: media?.title || metaContent?.meta_title,
            teams: match?.teams || {
                home: {
                    name: match?.homeTeam
                },
                away: {
                    name: match?.awayTeam
                }
            }
        };
    }, [metaContent, goalAPIData]);

    const headerContent = {
        background: header?.background,
        title: header?.title,
        subtitle: header?.subtitle,
        app_buttons: [
            {
                image: app_store_button?.image,
                link: app_store_button?.url,
                alt: 'Download de ESPN Goal! app in de App Store',
                track: 'campagne:download:apple-store'
            },
            {
                image: play_store_button?.image,
                link: play_store_button?.url,
                alt: 'Download de ESPN Goal! app in de Google Play Store',
                track: 'campagne:download:google-play'
            }
        ],
        ...header
    };

    const sortedSliderImages = useMemo(() => {
        if (!image_slider?.images?.data) return [];

        switch (image_slider.sort_by) {
            case 'random':
                return image_slider.images.data.sort(() => Math.random() - 0.5);

            case 'filename-ascending':
                return image_slider.images.data.sort((a, b) =>
                    a.attributes.name.localeCompare(b.attributes.name)
                );
            case 'filename-descending':
                return image_slider.images.data.sort((a, b) =>
                    b.attributes.name.localeCompare(a.attributes.name)
                );
            default:
                return image_slider.images.data;
        }
    }, [image_slider?.images?.data, image_slider?.sort_by]);

    return (
        <PageLayout id="goal" loading={loading}>
            {metaContent && <Meta data={metaContent} />}
            {goalAPIData ? (
                <GoalHeader data={goalHeaderContent} />
            ) : (
                <Header data={headerContent} />
            )}

            {text_block && <ImageCTABlock data={text_block} />}

            {sortedSliderImages?.length > 0 && (
                <div className="container slider-container">
                    <Slider {...SLIDER_CONFIG}>
                        {sortedSliderImages.map(image => (
                            <div key={image.id}>
                                <Image
                                    image={image?.attributes}
                                    className="img-responsive"
                                />
                            </div>
                        ))}
                    </Slider>
                </div>
            )}
            {goalAPIData && <QRCodeBlock />}
            <AppStoreBanner />
        </PageLayout>
    );
};

export default GoalVideo;
