import { useEffect, useMemo } from 'react';
import { CampaignBig, CampaignRow } from './blocks/Campaigns';

import Meta from '@components/meta/Meta';
import useRedirect404 from '@hooks/useRedirect404';
import useStrapiQueries from '@hooks/useStrapiQueries';
import PageLayout from '@layouts/PageLayout';
import { pageView } from '@scripts/analytics';
import Cta from './blocks/Cta';
import Header from './blocks/Header';
import ImageCTA from './blocks/ImageCTA';

const Frontpage = () => {
    const { useFetchFrontPage } = useStrapiQueries();
    const {
        data: frontPage,
        isLoading: frontPageLoading,
        error: frontPageError
    } = useFetchFrontPage();

    useRedirect404(frontPageLoading, frontPageError || frontPage?.error);

    useEffect(() => {
        pageView('espnOne');
    }, []);

    const { header, campaign_blocks, cta, banner, meta } =
        frontPage?.data?.attributes || {};

    const campaigns = useMemo(() => {
        return {
            highlighted: campaign_blocks?.find?.(block => block.highlighted),
            rest: campaign_blocks?.filter(block => !block.highlighted)
        };
    }, [campaign_blocks]);

    return (
        <PageLayout theme="dark" loading={frontPageLoading}>
            {meta && <Meta data={meta} />}

            {header && <Header data={header} />}

            {campaigns && (
                <div id="campaigns">
                    {campaigns?.highlighted && (
                        <CampaignBig {...campaigns.highlighted} />
                    )}

                    {campaigns?.rest && (
                        <CampaignRow
                            campaigns={campaigns?.rest?.map(
                                ({
                                    title,
                                    description,
                                    link,
                                    image,
                                    link_label
                                }) => ({
                                    title,
                                    description,
                                    link,
                                    image,
                                    linkLabel: link_label
                                })
                            )}
                        />
                    )}
                </div>
            )}

            {cta && <Cta data={cta} />}
            {banner && <ImageCTA data={banner} />}
        </PageLayout>
    );
};

export default Frontpage;
