import './Download.scss';

import appleTv from '@assets/images/apple-tv.png';
import ipadOverviewBottom from '@assets/images/bottom-bar-ipad-overview.png';
import ipadReadBottom from '@assets/images/bottom-bar-ipad-read.png';
import cactusLeft from '@assets/images/cactus-left.png';
import cactusRight from '@assets/images/cactus-right.png';
import ipadOverview from '@assets/images/top-bar-ipad-overview.png';
import ipadReadTop from '@assets/images/top-bar-ipad-read.png';
import tv2 from '@assets/images/tv-2.png';
import Meta from '@components/meta/Meta';
import { Button } from '@components/ui/Button';
import Image from '@components/ui/Image';
import useRedirect404 from '@hooks/useRedirect404';
import useStrapiQueries from '@hooks/useStrapiQueries';
import PageLayout from '@layouts/PageLayout';
import { pageView } from '@scripts/analytics';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from './blocks/Header';
import HighlightBlock from './blocks/HighlightBlock';

const Download = () => {
    const { useFetchDownloadPage } = useStrapiQueries();

    const {
        data: downloadPage,
        isLoading: downloadPageLoading,
        error: downloadPageError
    } = useFetchDownloadPage();

    useRedirect404(
        downloadPageLoading,
        downloadPageError || downloadPage?.error
    );

    useEffect(() => {
        pageView('download');
    }, []);

    const {
        meta,
        header_image,
        header_title,
        header_subtitle,
        app_store_button,
        play_store_button,
        summary_image,
        summary_title,
        summary_description,
        start_block,
        blocks,
        end_block
    } = downloadPage?.data?.attributes || {};

    const headerContent = {
        image: header_image,
        title: header_title,
        subtitle: header_subtitle,
        buttons: [
            {
                image: app_store_button?.image,
                link: app_store_button?.url,
                alt: 'Download de ESPN-app in de App Store',
                track: 'campagne:download:apple-store'
            },
            {
                image: play_store_button?.image,
                link: play_store_button?.url,
                alt: 'Download de ESPN-app in de Google Play Store',
                track: 'campagne:download:google-play'
            }
        ],
        summary: {
            title: summary_title,
            description: summary_description,
            image: summary_image
        }
    };

    return (
        <PageLayout loading={downloadPageLoading}>
            {meta && <Meta data={meta} />}
            <Header
                headerContent={headerContent}
                summaryContent={headerContent.summary}
            />

            {start_block && (
                <section className="start-block">
                    <div className="container">
                        <div className="text-container">
                            <h2 className="heading heading--medium">
                                {start_block.title}
                            </h2>
                            <p className="text">{start_block.description}</p>

                            <div className="button-container">
                                {start_block?.buttons?.map(button => (
                                    <Button
                                        radius="squared"
                                        key={button.id}
                                        type={
                                            button.type === 'primary'
                                                ? 'red'
                                                : button.type
                                        }
                                        link={button.url}
                                        target={
                                            button.is_external
                                                ? '_blank'
                                                : '_self'
                                        }
                                        label={button.label}
                                        showArrow
                                        theme="light"
                                    />
                                ))}
                            </div>
                        </div>

                        {start_block.image?.data && (
                            <div className="ipad-parallax ipad-overview">
                                <div className="image-container overview">
                                    <img
                                        className="top-bar"
                                        src={ipadOverview}
                                        alt="top bar van de ipad"
                                    />

                                    <Image
                                        className="screenshot image"
                                        image={
                                            start_block.image?.data?.attributes
                                        }
                                        alt="Screenshot van de ESPN-app op een tablet"
                                    />

                                    <img
                                        className="bottom-bar"
                                        src={ipadOverviewBottom}
                                        alt="bottom bar van de ipad"
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </section>
            )}

            {blocks?.length > 0 && (
                <div className="container">
                    <section className="highlight-blocks">
                        {blocks?.map((block, i) => (
                            <HighlightBlock
                                key={block.id}
                                data={block}
                                large={i === 0}
                            />
                        ))}
                    </section>
                </div>
            )}

            {end_block && (
                <section className="end-block">
                    <div className="container">
                        <div className="text-container">
                            <h2 className="heading heading--medium">
                                {end_block.title}
                            </h2>
                            <p className="text">{end_block.description}</p>
                            <div className="button-container">
                                {start_block?.buttons?.map(button => (
                                    <Button
                                        radius="squared"
                                        key={button.id}
                                        type={
                                            button.type === 'primary'
                                                ? 'red'
                                                : button.type
                                        }
                                        link={button.url}
                                        target={
                                            button.is_external
                                                ? '_blank'
                                                : '_self'
                                        }
                                        label={button.label}
                                        showArrow
                                        theme="light"
                                    />
                                ))}
                            </div>
                        </div>
                        {end_block.image?.data && (
                            <div className="ipad-parallax">
                                <div className="image-container read">
                                    <img
                                        className="top-bar"
                                        src={ipadReadTop}
                                        alt="top bar van de ipad"
                                    />

                                    <Image
                                        className="image"
                                        id="parallax"
                                        alt="Een schermafbeelding van de ESPN-app op een tablet"
                                        image={
                                            end_block.image?.data?.attributes
                                        }
                                    />

                                    <img
                                        className="bottom-bar"
                                        src={ipadReadBottom}
                                        alt="bottom bar van de ipad"
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </section>
            )}

            <section className="subscription">
                <div className="text-container">
                    <div className="content">
                        <h2 className="heading heading--large heading--white">
                            Nog geen abonnement?
                        </h2>
                        <p className="text text--white">
                            Ga naar{' '}
                            <Link
                                className="text text--white inline-link"
                                to="/abonneren"
                            >
                                espnkijken.nl
                            </Link>{' '}
                            om direct een abonnement af te sluiten via jouw
                            tv-aanbieder.
                        </p>
                        <Button
                            link="/abonneren"
                            type="primary"
                            size="large"
                            align="center"
                            label="Meer informatie"
                        />
                    </div>
                    <div className="image-container">
                        <img
                            src={tv2}
                            className="image"
                            alt="Een doelpunt tijdens een voetbalwedstrijd"
                        />
                    </div>
                    <img
                        src={appleTv}
                        className="image apple-tv hide-large-tablet"
                        alt="Een Apple TV"
                    />
                    <div className="cactusses">
                        <img
                            src={cactusLeft}
                            className="image cactus hide-large-tablet cactus__left"
                            alt="Een cactus"
                        />
                        <img
                            src={cactusRight}
                            className="image cactus hide-large-tablet cactus__right"
                            alt="Een cactus"
                        />
                    </div>
                </div>
            </section>
        </PageLayout>
    );
};

export default Download;
