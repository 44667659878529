import { extractAssetURL } from '@api/utils';
import Meta from '@components/meta/Meta';
import useRedirect404 from '@hooks/useRedirect404';
import useStrapiQueries from '@hooks/useStrapiQueries';
import PageLayout from '@layouts/PageLayout';
import { pageView } from '@scripts/analytics';
import MatchesList from 'components/lists/MatchesList';
import { useEffect } from 'react';
import Header from './blocks/Header';
import HeaderAlt from './blocks/Header/HeaderAlt';
import Video from './blocks/Video';

const Ontknoping = () => {
    const { useFetchOntknopingPage } = useStrapiQueries();

    const {
        data: pageData,
        isLoading: pageDataLoading,
        error: pageDataError
    } = useFetchOntknopingPage();

    useRedirect404(pageDataLoading, pageDataError || pageData?.error);

    useEffect(() => {
        pageView('ontknoping');
    }, []);

    const {
        meta,
        header,
        video,
        video_poster,
        match_overview_title,
        match_overview_subtitle,
        match_overview_footnote,
        match_overview_background
    } = pageData?.data?.attributes || {};

    const matchesContent = {
        title: match_overview_title,
        subtitle: match_overview_subtitle,
        footnote: match_overview_footnote,
        image: match_overview_background
    };

    return (
        <PageLayout theme="dark" loading={pageDataLoading}>
            {meta && <Meta data={meta} />}
            {header?.show_content && <Header data={header} />}
            {header && header?.show_content === false && (
                <HeaderAlt data={header} />
            )}

            <MatchesList
                bgImage={matchesContent.image}
                title={matchesContent.title}
            />

            {video && (
                <Video
                    video={extractAssetURL(video?.data?.attributes)}
                    poster={extractAssetURL(video_poster?.data?.attributes)}
                />
            )}
        </PageLayout>
    );
};

export default Ontknoping;
