import './Dynamic.scss';

import { useEffect, useMemo } from 'react';

import Meta from '@components/meta/Meta';
import useRedirect404 from '@hooks/useRedirect404';
import useStrapiQueries from '@hooks/useStrapiQueries';
import PageLayout from '@layouts/PageLayout';
import { pageView } from '@scripts/analytics';
import { useParams } from 'react-router-dom';
import FAQBlock from './blocks/FAQBlock';
import Header from './blocks/Header';
import ImageCTABanner from './blocks/ImageCTABanner';
import ImageCTABlock from './blocks/ImageCTABlock';
import SliderBlock from './blocks/SliderBlock';
import TextBlock from './blocks/TextBlock';
import VideoBlock from './blocks/VideoBlock';
import HorizontalImageBlocks from './layouts/HorizontalImageBlocks';
import MatchOverviewBlock from './blocks/MatchOverviewBlock';

const Dynamic = () => {
    const { slug } = useParams();

    const { useFetchDynamicPage } = useStrapiQueries();
    const {
        data: pageData,
        isLoading: pageDataLoading,
        error: pageDataError
    } = useFetchDynamicPage(slug);

    useRedirect404(pageDataLoading, pageDataError || pageData?.error);

    const { blocks, meta } = pageData?.data?.[0]?.attributes || {};

    const blocksMemo = useMemo(() => {
        return {
            header: blocks?.find(
                block => block.__component === 'blocks.header'
            ),
            content: blocks?.filter(
                block => block.__component !== 'blocks.header'
            )
        };
    }, [blocks]);

    useEffect(() => {
        pageView(slug);
    }, [slug]);

    return (
        <PageLayout loading={pageDataLoading}>
            {meta && <Meta data={meta} />}

            {blocksMemo?.header && <Header data={blocksMemo.header} />}
            {blocksMemo?.content?.map(block => {
                switch (block.__component) {
                    case 'blocks.match-overview':
                        return (
                            <MatchOverviewBlock key={block.id} data={block} />
                        );
                    case 'blocks.image-cta-block':
                        return <ImageCTABlock key={block.id} data={block} />;
                    case 'blocks.image-cta-banner':
                        return <ImageCTABanner key={block.id} data={block} />;
                    case 'blocks.text-block':
                        return <TextBlock key={block.id} data={block} />;
                    case 'blocks.video-block':
                        return <VideoBlock key={block.id} data={block} />;
                    case 'blocks.faq':
                        return <FAQBlock key={block.id} data={block} />;
                    case 'block-layout.dual-blocks':
                        return (
                            <HorizontalImageBlocks
                                key={block.id}
                                data={block}
                            />
                        );
                    case 'block-layout.blocks-slider':
                        return <SliderBlock key={block.id} data={block} />;
                    default:
                        return null;
                }
            })}
        </PageLayout>
    );
};

export default Dynamic;
