import './ImageBlock.scss';

import { extractAssetURL } from '@api/utils';
import { Arrow } from '@components/ui/Icons';
import { Link } from 'react-router-dom';

const ImageBlock = ({ data }) => {
    const imageWrapperStyle = {
        backgroundImage: `url(${extractAssetURL(data?.image?.data?.attributes)})`
    };

    return (
        <article className="image-block">
            <div
                className="image-block-image-wrapper"
                style={imageWrapperStyle}
            />
            <header>
                <h2 className="image-block-title">{data?.title}</h2>
            </header>
            <section className="image-block-footer">
                <Link to={data?.link_url} className="link arrow">
                    {data?.link_label}
                    <Arrow />
                </Link>
            </section>
        </article>
    );
};

export default ImageBlock;
