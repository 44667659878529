import API from '@api/api';
import qs from 'qs';

const populateConfig = {
    logo: true,
    clubs: {
        populate: ['logo']
    }
};

const competitionsQueries = {
    getPageData: {
        key: 'competitionsData',
        fetcher: async () => {
            const query = qs.stringify(
                {
                    populate: populateConfig
                },

                {
                    encodeValuesOnly: true // prettify URL
                }
            );

            return API.fetch(`/competitions?${query}`);
        }
    }
};

export { competitionsQueries };
