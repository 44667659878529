import Image from '@components/ui/Image';
import { Link } from 'react-router-dom';

const ImageCTA = ({ data }) => {
    return (
        <div className="container">
            <Link to={data?.url}>
                <Image
                    image={data?.image?.data?.attributes}
                    size="large"
                    style={{ width: '100%' }}
                />
            </Link>
        </div>
    );
};

export default ImageCTA;
