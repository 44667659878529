import './ImageCTABlock.scss';

import { extractAssetURL, parseMarkdownContent } from '@api/utils';

import { Button } from '@components/ui/Button';
import classNames from 'classnames';

const ImageCTABlock = ({ data }) => {
    const contentClassNames = classNames('content', {
        'content--ltr': data?.direction === 'left-to-right',
        'content--rtl': data?.direction === 'right-to-left'
    });

    const imageWrapperStyle = {
        backgroundImage: `url(${extractAssetURL(data?.image?.data?.attributes)})`
    };

    return (
        <div className="image-cta-block">
            <div className="container">
                <div className={contentClassNames}>
                    <div className="image-wrapper" style={imageWrapperStyle} />
                    <div className="text-wrapper">
                        <span className="label">{data?.label}</span>

                        <h2 className="heading heading--medium">
                            {data?.title}
                        </h2>
                        {data?.text?.length > 0 && (
                            <section className="text">
                                {parseMarkdownContent(data?.text)}
                            </section>
                        )}

                        <div className="button-container">
                            {data?.buttons?.map(button => (
                                <Button
                                    radius="squared"
                                    key={button.id}
                                    type={
                                        button.type === 'primary'
                                            ? 'red'
                                            : button.type
                                    }
                                    link={button.url}
                                    target={
                                        button.is_external ? '_blank' : '_self'
                                    }
                                    label={button.label}
                                    showArrow
                                    theme="light"
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ImageCTABlock;
