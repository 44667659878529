import './Abonneren.scss';

import { useCallback, useEffect } from 'react';

import ExpandableGrid from './blocks/ExpandableGrid/ExpandableGrid';
import Header from './blocks/Header';
import ImageCTA from '../Frontpage/blocks/ImageCTA';
import Meta from '@components/meta/Meta';
import PageLayout from '@layouts/PageLayout';
import { pageView } from '@scripts/analytics';
import useRedirect404 from '@hooks/useRedirect404';
import useStrapiQueries from '@hooks/useStrapiQueries';

const Abonneren = () => {
    const { useFetchAbonnerenPage } = useStrapiQueries();
    const {
        data: pageData,
        isLoading: pageDataLoading,
        error: pageDataError
    } = useFetchAbonnerenPage();

    useEffect(() => {
        pageView('abonneren');
    }, []);

    useRedirect404(pageDataLoading, pageDataError || pageData?.error);

    const gtag_report_conversion = url => {
        const callback = () => {
            if (typeof url !== 'undefined') {
                window.location = url;
            }
        };
        window.gtag('event', 'conversion', {
            send_to: 'AW-329376570/FaU5CMeB89AYELrGh50B',
            event_callback: callback
        });
        return false;
    };

    const logoClickEvent = useCallback(
        providerName => {
            window.dataLayer = window.dataLayer || [];

            window.dataLayer.push({
                event: 'providerLogoClick',
                providerName: providerName
            });

            window.gtag('event', 'conversion', {
                allow_custom_scripts: true,
                u1: 'ESPN Campagne Abonneren',
                u98: providerName,
                send_to: 'DC-9800068/espn-c/nl_es0+standard'
            });

            gtag_report_conversion();
        },
        [gtag_report_conversion]
    );

    const { meta, providers, header, banner } =
        pageData?.data?.attributes || {};

    return (
        <PageLayout theme="dark" loading={pageDataLoading}>
            {meta && (
                <Meta data={meta}>
                    {/* <!-- Google Tag Manager --> */}
                    <script>
                        {((w, d, s, l, i) => {
                            w[l] = w[l] || [];
                            w[l].push({
                                'gtm.start': new Date().getTime(),
                                event: 'gtm.js'
                            });
                            var f = d.getElementsByTagName(s)[0],
                                j = d.createElement(s),
                                dl = l !== 'dataLayer' ? '&l=' + l : '';
                            j.async = true;
                            j.src =
                                'https://www.googletagmanager.com/gtm.js?id=' +
                                i +
                                dl;
                            f.parentNode.insertBefore(j, f);
                        })(
                            window,
                            document,
                            'script',
                            'dataLayer',
                            'GTM-PQ7TFLN'
                        )}
                    </script>
                    {/* <!-- End Google Tag Manager --> */}
                </Meta>
            )}

            {header && <Header data={header} />}

            {providers && (
                <ExpandableGrid
                    disableExpand
                    id="providers"
                    data={providers}
                    theme="dark"
                    logoClickEvent={logoClickEvent}
                />
            )}

            {banner && <ImageCTA data={banner} />}
        </PageLayout>
    );
};

export default Abonneren;
