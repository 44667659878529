import './FAQBlock.scss';

import { Minus, Plus } from '@components/ui/Icons';

import { useState } from 'react';

const FAQBlock = ({ data }) => {
    const [activeIndex, setActiveIndex] = useState(null);

    return (
        <section className="faq-block">
            <div className="container">
                <h2 className="title">{data?.title}</h2>

                <ul className="faq-list">
                    {data?.items?.map((faq, index) => (
                        <li
                            key={faq.id}
                            className={`faq-item ${
                                activeIndex === index ? 'active' : ''
                            }`}
                        >
                            {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
                            <div
                                className="faq-item__header"
                                onClick={() =>
                                    setActiveIndex(
                                        index === activeIndex ? null : index
                                    )
                                }
                            >
                                <h4>{faq.question}</h4>
                                <span className="icon">
                                    {activeIndex === index ? (
                                        <Minus />
                                    ) : (
                                        <Plus />
                                    )}
                                </span>
                            </div>

                            {activeIndex === index && (
                                <div className="faq-content">{faq.answer}</div>
                            )}
                        </li>
                    ))}
                </ul>
            </div>
        </section>
    );
};

export default FAQBlock;
