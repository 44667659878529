import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import topVisual from '@assets/images/frontpage-top-2.webp';
import Meta from '@components/meta/Meta';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { STRAPI_URL } from 'env';
import PageRoutes from './Routes';
import DEFAULT_META from './config/defaultMeta';

// Init React Query client
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: 1
        }
    }
});

const ReactQueryDevtoolsProduction = React.lazy(() =>
    import('@tanstack/react-query-devtools/build/modern/production.js').then(
        d => ({
            default: d.ReactQueryDevtools
        })
    )
);

function App() {
    const [showDevtools, setShowDevtools] = useState(false);

    useEffect(() => {
        console.log('App started, using endpoint: ', STRAPI_URL);
        window.toggleDevtools = () => setShowDevtools(old => !old);
    }, []);

    return (
        <QueryClientProvider client={queryClient}>
            <Meta
                data={{
                    ...DEFAULT_META,
                    image: topVisual
                }}
            />

            <ReactQueryDevtools initialIsOpen={false} />
            {showDevtools && (
                <React.Suspense fallback={null}>
                    <ReactQueryDevtoolsProduction />
                </React.Suspense>
            )}
            <Router>
                <Routes>
                    {PageRoutes.map(route => {
                        return (
                            <Route
                                path={route.path}
                                element={route.page}
                                key={route.path}
                            />
                        );
                    })}
                </Routes>
            </Router>
        </QueryClientProvider>
    );
}

export default App;
