import API from '@api/api';
import qs from 'qs';

const populateConfig = {
    blocks: {
        populate: [
            'blocks.image',
            'cover',
            'video',
            'background',
            'image',
            'buttons',
            'items'
        ]
    },
    meta: {
        populate: ['image']
    }
};

const dynamicPageQueries = {
    getPageData: {
        key: 'dynamicPageData',
        fetcher: async slug => {
            const query = qs.stringify(
                {
                    populate: populateConfig,
                    filters: {
                        slug: {
                            $eq: slug
                        }
                    }
                },
                {
                    encodeValuesOnly: true // prettify URL
                }
            );

            return API.fetch(`/pages?${query}`);
        }
    }
};

export { dynamicPageQueries };
