import './Header.scss';

import { extractAssetURL, parseMarkdownContent } from '@api/utils';

import { Button } from '@components/ui/Button';
import classNames from 'classnames';

const Header = ({ data }) => {
    const headerStyle = {
        background: `url(${extractAssetURL(data?.background?.data?.attributes)}) center center / cover rgba(0, 2, 0, 0.34)`
    };

    const headerInnerClasses = classNames('container', 'container--inner', {
        'text--center': data?.position === 'center',
        'text--right': data?.position === 'right',
        'text--left': data?.position === 'left'
    });

    const headerContentClasses = classNames('content', {
        'content--center': data?.position === 'center',
        'content--right': data?.position === 'right',
        'content--left': data?.position === 'left'
    });

    return (
        <header className="Header__Fairplay" style={headerStyle}>
            <div className={headerInnerClasses}>
                <h1 className="heading heading--large heading--white">
                    {data?.title}
                </h1>

                <section className={headerContentClasses}>
                    {data?.content && parseMarkdownContent(data.content)}
                </section>

                <div className="button-container">
                    {data?.buttons?.map(button => (
                        <Button
                            key={button.id}
                            type={button.type}
                            link={button.url}
                            target={button.is_external ? '_blank' : '_self'}
                            label={button.label}
                            theme="dark"
                        />
                    ))}
                </div>
            </div>
        </header>
    );
};

export default Header;
