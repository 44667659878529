import './Navbar.scss';

import { useEffect, useState } from 'react';

import logo from '@assets/images/logo-espn.svg';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

const Navbar = () => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);

    const classnames = classNames('Navbar', {
        sticky: scrollPosition > 200
    });

    return (
        <nav className={classnames}>
            <div className="container">
                <Link to="/" className="logo logo--white">
                    <img src={logo} alt="ESPN logo" />
                </Link>
            </div>
        </nav>
    );
};

export default Navbar;
