import API from '@api/api';
import qs from 'qs';

const populateConfig = {
    app_store_button: {
        populate: 'image'
    },
    play_store_button: {
        populate: 'image'
    },
    header_image: true,
    summary_image: true,
    start_block: {
        populate: ['image', 'buttons']
    },
    end_block: {
        populate: ['image', 'buttons']
    },
    blocks: {
        populate: ['foreground_image', 'background_image']
    },

    meta: {
        populate: ['image']
    }
};

const downloadPageQueries = {
    getPageData: {
        key: 'downloadPageData',
        fetcher: async () => {
            const query = qs.stringify(
                {
                    populate: populateConfig
                },
                {
                    encodeValuesOnly: true // prettify URL
                }
            );

            return API.fetch(`/download-page?${query}`);
        }
    }
};

export { downloadPageQueries };
