import './Spinner.scss';

import classNames from 'classnames';

const Spinner = ({
    label,
    size = 'block',
    containerClassName,
    spinnerClassName
}) => {
    const containerClasses = classNames(
        'spinner-container',
        {
            inline: size === 'inline',
            block: size === 'block',
            page: size === 'page'
        },
        containerClassName
    );

    const spinnerClasses = classNames(
        'spinner',
        {
            inline: size === 'inline',
            block: size === 'block',
            page: size === 'page'
        },
        spinnerClassName
    );

    return (
        <div className={containerClasses}>
            <div className={spinnerClasses} />
            {label && <div className="spinner-label">{label}</div>}
        </div>
    );
};

export default Spinner;
