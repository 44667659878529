import classNames from 'classnames';

const Arrow = ({ className }) => {
    return (
        <svg
            className={classNames('svg-arrow', className)}
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <title>Arrow icon</title>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.9571 2.55078L19.3308 9.92455L11.9571 17.2984L10.5429 15.8842L15.5024 10.9246H2.49994V8.92455H15.5024L10.5429 3.96499L11.9571 2.55078Z"
            />
        </svg>
    );
};

export default Arrow;
