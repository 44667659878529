import { extractAssetURL } from '@api/utils';

const Image = ({ image, alt, size, className, style }) => {
    const imageURL = () => {
        if (!image?.formats) return extractAssetURL(image);

        switch (size) {
            case 'thumbnail':
                return extractAssetURL(image?.formats?.thumbnail);
            case 'small':
                return extractAssetURL(image?.formats?.small);
            case 'medium':
                return extractAssetURL(image?.formats?.medium);
            case 'large':
                return extractAssetURL(image?.formats?.large);
            default:
                return extractAssetURL(image);
        }
    };

    return (
        <img
            style={style}
            src={imageURL()}
            alt={alt ?? image?.alternativeText}
            className={className}
        />
    );
};

export default Image;
