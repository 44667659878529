import './Video.scss';

const Video = ({ video, poster }) => {
    return (
        <section className="Video">
            <div className="Video__container">
                <video className="track" controls poster={poster}>
                    <source src={video} type="video/mp4" />
                    <track kind="captions" label="English" />
                </video>
            </div>
        </section>
    );
};

export default Video;
