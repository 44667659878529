import './Tile.scss';

import { Button } from '@components/ui/Button';
import Image from '@components/ui/Image';
import { useState } from 'react';
import Modal from 'react-modal';

const Tile = ({ tile, logoClickEvent }) => {
    const [modalIsOpen, setIsOpen] = useState(false);

    const openModal = () => {
        setIsOpen(true);
        logoClickEvent(tile.name);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    return (
        <>
            {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
            <div className="Tile" onClick={openModal}>
                <Image
                    image={tile?.logo?.data?.attributes}
                    size="small"
                    alt={`${tile.name} Logo`}
                />

                <span className="text">Selecteer</span>
            </div>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={{
                    overlay: {
                        backgroundColor: null
                    },
                    content: {
                        position: null,
                        border: null,
                        borderRadius: null,
                        background: null,
                        padding: null
                    }
                }}
            >
                <div className="modal-header">
                    <Image
                        image={tile?.logo?.data?.attributes}
                        size="small"
                        alt={`${tile.name} Logo`}
                    />
                    {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="close"
                        onClick={closeModal}
                        width="12"
                        height="12"
                        fill="#fff"
                        viewBox="0 0 12 12"
                    >
                        <title>Close icon</title>
                        <path
                            fillRule="evenodd"
                            d="M7.31 5.9l4.25-4.24L10.14.24 5.9 4.5 1.66.24.24 1.66 4.5 5.9.24 10.14l1.42 1.42L5.9 7.3l4.24 4.25 1.42-1.42L7.3 5.9z"
                            clipRule="evenodd"
                        />
                    </svg>
                </div>
                <div className="modal-body">
                    <p className="text text--white modal-text">
                        Ben je al klant bij <strong>{tile.name}</strong>?
                    </p>
                    {tile.link && tile.label && (
                        <>
                            <Button
                                label={`Zet ESPN aan in ${tile.label}`}
                                link={tile.link}
                                type="primary"
                                size="large"
                                target="blank"
                                track={`campagne:abonneren:${tile.label}`}
                            />
                            <span className="text text--white modal-divider">
                                Of
                            </span>
                        </>
                    )}
                    <Button
                        label="Meer informatie"
                        link={tile.secondaryLink}
                        type="secondary"
                        size="large"
                        target="blank"
                        track={`campagne:abonneren:${tile.label}-meer-informatie`}
                    />
                </div>
                <div className="modal-footer">
                    <p className="text text--white text--center">
                        Na je klik verbinden we je door naar{' '}
                        <span>{tile.name}</span>
                    </p>
                </div>
            </Modal>
        </>
    );
};

export default Tile;
