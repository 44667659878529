import { GOAL_API_URL, GOAL_AUTH_TOKEN } from 'env';

import API from '@api/api';
import qs from 'qs';

const populateConfig = {
    header: {
        populate: ['background', 'buttons']
    },
    meta: {
        populate: ['image']
    },
    app_store_button: {
        populate: ['image']
    },
    play_store_button: {
        populate: ['image']
    },
    text_block: {
        populate: ['image', 'buttons']
    },
    image_slider: {
        populate: ['images']
    }
};

const goalPageQueries = {
    getPageData: {
        key: 'goalPageData',
        fetcher: async () => {
            const query = qs.stringify(
                {
                    populate: populateConfig
                },
                {
                    encodeValuesOnly: true // prettify URL
                }
            );

            return API.fetch(`/goal-page?${query}`);
        }
    },
    getGoalData: {
        key: 'goalData',
        fetcher: async ({ matchId, mediaId }) => {
            const response = await fetch(
                `${GOAL_API_URL}/v2/media?matchId=${matchId}&mediaId=${mediaId}`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: GOAL_AUTH_TOKEN
                    }
                }
            );
            const res = await response.json();

            return res?.data;
        }
    }
};

export { goalPageQueries };
