import adoDenhaag from '../assets/icons/Clubs/adoDenHaag.png';
import ajax from '../assets/icons/Clubs/ajax.png';
import almereCity from '../assets/icons/Clubs/almereCity.png';
import az from '../assets/icons/Clubs/az.png';
import excelsior from '../assets/icons/Clubs/excelsior.png';
import fcGroningen from '../assets/icons/Clubs/fc-groningen.png';
import fcTwente from '../assets/icons/Clubs/fc-twente.png';
import fcUtrecht from '../assets/icons/Clubs/fc-utrecht.png';
import fcVolendam from '../assets/icons/Clubs/fc-volendam.png';
import fcDenBosch from '../assets/icons/Clubs/fcDenBosch.png';
import fcDordrecht from '../assets/icons/Clubs/fcDordrecht.png';
import fcEindhoven from '../assets/icons/Clubs/fcEindhoven.png';
import fcEmmen from '../assets/icons/Clubs/fcEmmen.png';
import feyenoord from '../assets/icons/Clubs/feyenoord.png';
import fortunaSittard from '../assets/icons/Clubs/fortuna-sittard.png';
import goAheadEagles from '../assets/icons/Clubs/goAheadEagles.png';
import graafschap from '../assets/icons/Clubs/graafschap.png';
import helmond from '../assets/icons/Clubs/helmond.png';
import heracles from '../assets/icons/Clubs/heracles.png';
import mvvMaastricht from '../assets/icons/Clubs/mvvMaastricht.png';
import nacBreda from '../assets/icons/Clubs/nacBreda.png';
import nec from '../assets/icons/Clubs/nec.png';
import pecZwolle from '../assets/icons/Clubs/pecZwolle.png';
import psv from '../assets/icons/Clubs/psv.png';
import rkcWaalwijk from '../assets/icons/Clubs/rkcWaalwijk.png';
import rodaJc from '../assets/icons/Clubs/rodaJc.png';
import scCambuur from '../assets/icons/Clubs/sc-cambuur.png';
import scHeerenveen from '../assets/icons/Clubs/sc-heerenveen.png';
import scHeerenveenVrouwen from '../assets/icons/Clubs/scHeerenveenVrouwen.png';
import sparta from '../assets/icons/Clubs/sparta.png';
import telstar from '../assets/icons/Clubs/telstar.png';
import topOss from '../assets/icons/Clubs/topOss.png';
import vitesse from '../assets/icons/Clubs/vitesse.png';
import vvAlkmaarVrouwen from '../assets/icons/Clubs/vvAlkmaarVrouwen.png';
import vvvVenlo from '../assets/icons/Clubs/vvvVenlo.png';
import willemII from '../assets/icons/Clubs/willemII.png';

const CLUBS = [
    { name: 'Ajax', image: ajax },
    { name: 'Feyenoord', image: feyenoord },
    { name: 'PSV', image: psv },
    { name: 'AZ', image: az },
    { name: 'FC Twente', image: fcTwente },
    { name: 'FC Groningen', image: fcGroningen },
    { name: 'FC Utrecht', image: fcUtrecht },
    { name: 'Fortuna Sittard', image: fortunaSittard },
    { name: 'SC Heerenveen', image: scHeerenveen },
    { name: 'SC Cambuur', image: scCambuur },
    { name: 'Vitesse', image: vitesse },
    { name: 'N.E.C. Nijmegen', image: nec },
    { name: 'Sparta', image: sparta },
    { name: 'RKC Waalwijk', image: rkcWaalwijk },
    { name: 'Go Ahead Eagles', image: goAheadEagles },
    { name: 'Excelsior', image: excelsior },
    { name: 'FC Emmen', image: fcEmmen },
    { name: 'FC Volendam', image: fcVolendam },
    { name: 'Willem II', image: willemII },
    { name: 'VVV-Venlo', image: vvvVenlo },
    { name: 'Telstar', image: telstar },
    { name: 'TOP Oss', image: topOss },
    { name: 'Roda JC', image: rodaJc },
    { name: 'PEC Zwolle', image: pecZwolle },
    { name: 'NAC Breda', image: nacBreda },
    { name: 'MVV Maastricht', image: mvvMaastricht },
    { name: 'Heracles', image: heracles },
    { name: 'Helmond Sport', image: helmond },
    { name: 'FC Eindhoven', image: fcEindhoven },
    { name: 'FC Dordrecht', image: fcDordrecht },
    { name: 'FC Den Bosch', image: fcDenBosch },
    { name: 'De Graafschap', image: graafschap },
    { name: 'Almere City', image: almereCity },
    { name: 'ADO Den Haag', image: adoDenhaag },
    { name: 'SC Heerenveen Vrouwen', image: scHeerenveenVrouwen },
    { name: 'VV Alkmaar Vrouwen', image: vvAlkmaarVrouwen },
    { name: 'Cambuur', image: scCambuur }
];

const getClubLogo = clubName => {
    const clubNameLowerCase = clubName.toLowerCase();

    const club = CLUBS.find(
        club =>
            club.name.toLowerCase() === clubNameLowerCase ||
            clubNameLowerCase.includes(club.name.toLowerCase())
    );
    return club ? club.image : null;
};

export default getClubLogo;
