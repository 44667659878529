import API from '@api/api';
import qs from 'qs';

const populateConfig = {
    header: {
        populate: ['background', 'buttons']
    },
    providers: {
        populate: ['providers.logo']
    },
    meta: {
        populate: ['image']
    }
};

const espnOneQueries = {
    getPageData: {
        key: 'espnOnePageData',
        fetcher: async () => {
            const query = qs.stringify(
                {
                    populate: populateConfig
                },
                {
                    encodeValuesOnly: true // prettify URL
                }
            );

            return API.fetch(`/espn-one?${query}`);
        }
    }
};

export { espnOneQueries };
